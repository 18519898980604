import {useConfigProvider} from "../../../config/ConfigProvider"

export function clientsEndpoints() {
  const {token, faToken, apimKey, serverUrl} = useConfigProvider()
  const headers = {
    "Content-Type": "application/json",
    "X-Functions-Key": faToken || "",
    "Ocp-Apim-Subscription-Key": apimKey || "",
    Authorization: `Bearer ${token}`,
  }

  const getAllClients = async (query: string) => {
    const request = `${serverUrl}/clients`
    return await fetch(`${request}?${query}&limit=9999`, {
      headers: headers,
    }).then((res) => res.json())
  }

  const getClientsByOwnerId = async (ownerId: string) => {
    const request = `${serverUrl}/owner/clients/${ownerId}`
    return await fetch(`${request}?limit=9999`, {
      headers: headers,
    }).then((res) => res.json())
  }
  const getClientsByUserId = async (query: string) => {
    const request = `${serverUrl}/user/clients`
    return await fetch(`${request}?${query}`, {
      headers: headers,
    }).then((res) => res.json())
  }

  const getClientByProjectId = async (projectId: string) => {
    const request = `${serverUrl}/projects/${projectId}/clients`
    return await fetch(request, {headers: headers}).then((res) => res.json())
  }

  return {
    getAllClients,
    getClientsByOwnerId,
    getClientsByUserId,
    getClientByProjectId,
  }
}
