import {type FC, memo, useState, Fragment} from "react"
import {useLocation, useNavigate} from "react-router-dom"
import {Button, Divider, Drawer, Stack, useTheme} from "@mui/material"
import {ReactSVG} from "react-svg"
import {Tooltip} from "../../atoms"
import SupportModal from "../SupportModal/SupportModal"
import {sideBarItems} from "./items"
import usePageStore from "../../../stores/pageStore"
import {useTranslation} from "react-i18next"

type Props = {
  isError?: boolean
}

const SideBar: FC<Props> = (props) => {
  const {isError = false} = props
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [showSupportModal, setShowSupportModal] = useState<boolean>(false)
  const [showAskAnExpert, setShowAskAnExpert] = useState<boolean>(false)
  const BUTTON_HEIGHT = "46.9px"
  const currentPage = usePageStore((state) => state.currentPage)
  const theme = useTheme()
  const navigate = useNavigate()
  const location = useLocation()
  const {t} = useTranslation()

  const handleToggle = () => {
    setIsOpen((open) => !open)
  }

  const handleSupportModal = () => {
    setShowSupportModal((open) => !open)
  }

  const handleAskAnExpertModal = () => {
    const show = showAskAnExpert
    setShowAskAnExpert((open) => !open)
    const event = new CustomEvent("ask-an-expert", {
      detail: {
        show: !show,
        relatesTo: currentPage,
      },
    })
    window.dispatchEvent(event)
  }

  return (
    <Fragment>
      <Stack
        position="absolute"
        top={"88px"}
        component="aside"
        direction="column"
        width="3.5rem"
        bgcolor="#053747"
        color={theme.palette.common.white}
        height="calc(100vh - 88px)"
        boxSizing="border-box"
        zIndex={101}
        role="menu"
        aria-label="main navigation"
        aria-expanded={isOpen}
        left={0}
      >
        <Stack height="408px" justifyContent="space-between">
          <Stack
            direction="column"
            spacing="0.5rem"
            width="100%"
            component="nav"
            role="navigation"
            boxSizing="border-box"
            alignItems="stretch"
            pt="2rem"
          >
            {sideBarItems.map((item, i) => (
              <Fragment key={`${item.title}_${i}`}>
                {item.type === "component" ? (
                  item.component
                ) : !item.action ? (
                  <Tooltip
                    title={t(item.title)}
                    placement="right"
                    arrow
                    slotProps={{
                      popper: {
                        modifiers: [
                          {name: "offset", options: {offset: [0, -17]}},
                        ],
                      },
                    }}
                  >
                    <Button
                      variant="text"
                      sx={{
                        minWidth: "unset",
                        color: theme.palette.common.white,
                        textTransform: "none",
                        height: BUTTON_HEIGHT,
                        py: "0.625rem",
                        borderRadius: "0px",
                        backgroundColor:
                          location.pathname === item.link ||
                          (item.match && location.pathname.includes(item.match))
                            ? theme.palette.info.main
                            : undefined,
                        "&:hover": {
                          backgroundColor: "#668A96",
                        },
                        div: {
                          height: "24px",
                        },
                        svg: {
                          outline: "none",
                          height: "24px",
                          width: "24px",
                        },
                      }}
                      color="primary"
                      onClick={() =>
                        item.link
                          ? isError && location.pathname === item.link
                            ? (window.location.href = item.link)
                            : navigate(item.link)
                          : null
                      }
                      role="link"
                      type="button"
                      aria-label={item.title}
                      aria-controls={item.title}
                    >
                      {item.icon}
                    </Button>
                  </Tooltip>
                ) : (
                  <Tooltip
                    title={t(item.title)}
                    placement="right"
                    arrow
                    slotProps={{
                      popper: {
                        modifiers: [
                          {name: "offset", options: {offset: [0, -17]}},
                        ],
                      },
                    }}
                  >
                    <Button
                      variant="text"
                      sx={{
                        minWidth: "unset",
                        color: theme.palette.common.white,
                        textTransform: "none",
                        height: BUTTON_HEIGHT,
                        py: "0.625rem",
                        borderRadius: "0px",
                        backgroundColor:
                          location.pathname === item.link ||
                          (item.match && location.pathname.includes(item.match))
                            ? theme.palette.info.main
                            : undefined,
                        "&:hover": {
                          backgroundColor: "#668A96",
                        },
                        div: {
                          height: "24px",
                        },
                        svg: {
                          outline: "none",
                          height: "24px",
                          width: "24px",
                        },
                      }}
                      color="primary"
                      onClick={() =>
                        item.action && item.action(handleAskAnExpertModal)
                      }
                      role="link"
                      type="button"
                      aria-label={item.title}
                      aria-controls={item.title}
                    >
                      {item.icon}
                    </Button>
                  </Tooltip>
                )}
              </Fragment>
            ))}
          </Stack>
          <Button
            sx={{
              minWidth: "unset",
              height: BUTTON_HEIGHT,
              py: "0.625rem",
              color: theme.palette.common.white,
              borderRadius: "0px",
              "&:hover": {
                backgroundColor: "#668A96",
              },
              div: {
                height: "24px",
              },
              svg: {
                height: "24px",
              },
            }}
            color="primary"
            onClick={handleToggle}
            role="button"
            type="button"
            title="Toggle sidebar"
            aria-label="Toggle sidebar"
            aria-controls="sidebar"
          >
            <ReactSVG src="/icons/chevron-right.svg" />
          </Button>
        </Stack>
        <Divider color="#CACDCD" />
        <Stack>
          <Tooltip
            title={t("Client support")}
            placement="right"
            arrow
            slotProps={{
              popper: {
                modifiers: [{name: "offset", options: {offset: [0, -17]}}],
              },
            }}
          >
            <Button
              sx={{
                minWidth: "unset",
                color: theme.palette.common.white,
                borderRadius: "0px",
                height: BUTTON_HEIGHT,
                "&:hover": {
                  backgroundColor: "#668A96",
                },
                div: {
                  height: "24px",
                },
                svg: {
                  height: "24px",
                  width: "24px",
                },
              }}
              color="primary"
              onClick={handleSupportModal}
              role="button"
              type="button"
              aria-label="client support"
              aria-controls="sidebar"
            >
              <ReactSVG src="/icons/help.svg" />
            </Button>
          </Tooltip>
        </Stack>
      </Stack>
      <Drawer
        variant="temporary"
        anchor="left"
        open={isOpen}
        onClose={handleToggle}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          zIndex: 102,
          "& .MuiDrawer-paper": {
            marginTop: "88px",
            height: "calc(100vh - 88px)",
            width: "224px",
            backgroundColor: "#053747",
            color: theme.palette.common.white,
          },
        }}
      >
        <Stack
          direction="column"
          width="100%"
          boxSizing="border-box"
          role="menu"
          aria-label="main navigation"
          aria-expanded={isOpen}
          gap="0.5rem"
          pt="2rem"
        >
          {sideBarItems.map((item, i) => (
            <Fragment key={`${item.title}_${i}`}>
              {item.type === "component" ? (
                item.component
              ) : !item.action ? (
                <Button
                  variant="text"
                  sx={{
                    textAlign: "left",
                    justifyContent: "flex-start",
                    minWidth: "unset",
                    color: theme.palette.common.white,
                    textTransform: "none",
                    height: BUTTON_HEIGHT,
                    py: "0.625rem",
                    px: "1.5rem",
                    gap: "0.75rem",
                    borderRadius: "0px",
                    backgroundColor:
                      location.pathname === item.link ||
                      (item.match && location.pathname.includes(item.match))
                        ? theme.palette.info.main
                        : undefined,
                    div: {
                      height: "24px",
                      svg: {
                        maxHeight: "24px",
                      },
                    },
                    svg: {
                      height: "24px",
                      width: "24px",
                    },
                    "&:hover": {
                      backgroundColor: "#668A96",
                    },
                  }}
                  color="primary"
                  onClick={() =>
                    item.link
                      ? isError && location.pathname === item.link
                        ? (window.location.href = item.link)
                        : navigate(item.link)
                      : null
                  }
                  role="link"
                  type="button"
                  aria-label={item.title}
                  aria-controls={item.title}
                >
                  {item.icon}&nbsp;{t(item.title)}
                </Button>
              ) : (
                <Button
                  variant="text"
                  sx={{
                    textAlign: "left",
                    justifyContent: "flex-start",
                    minWidth: "unset",
                    color: theme.palette.common.white,
                    textTransform: "none",
                    height: BUTTON_HEIGHT,
                    py: "0.625rem",
                    px: "1.5rem",
                    gap: "0.75rem",
                    borderRadius: "0px",
                    backgroundColor:
                      location.pathname === item.link ||
                      (item.match && location.pathname.includes(item.match))
                        ? theme.palette.info.main
                        : undefined,
                    div: {
                      maxHeight: "24px",
                      svg: {
                        maxHeight: "24px",
                      },
                    },
                    "&:hover": {
                      backgroundColor: "#668A96",
                    },
                    svg: {
                      height: "24px",
                      width: "24px",
                    },
                  }}
                  color="primary"
                  onClick={() =>
                    item.action && item.action(handleAskAnExpertModal)
                  }
                  role="link"
                  type="button"
                  aria-label={item.title}
                  aria-controls={item.title}
                >
                  {item.icon}&nbsp;{t(item.title)}
                </Button>
              )}
            </Fragment>
          ))}
        </Stack>
        <Stack
          direction="column"
          width="100%"
          px="0.625rem"
          py={1}
          boxSizing="border-box"
          minHeight="40%"
          justifyContent="end"
        >
          <Button
            sx={{
              minWidth: "unset",
              minHeight: "44px",
              color: theme.palette.common.white,
              alignSelf: "flex-end",
              div: {
                height: "24px",
              },
            }}
            color="primary"
            onClick={handleToggle}
            role="button"
            type="button"
            title="Toggle sidebar"
            aria-label="Toggle sidebar"
            aria-controls="sidebar"
          >
            <ReactSVG src="/icons/chevron-left-2.svg" />
          </Button>
          <Divider color="#CACDCD" />
        </Stack>
        <Stack>
          <Button
            variant="text"
            sx={{
              textAlign: "left",
              justifyContent: "flex-start",
              alignItems: "center",
              minWidth: "unset",
              color: theme.palette.common.white,
              textTransform: "none",
              height: BUTTON_HEIGHT,
              py: "0.625rem",
              px: "1.5rem",
              gap: "0.75rem",
              borderRadius: "0px",
              div: {
                height: "24px",
              },
              "&:hover": {
                backgroundColor: "#668A96",
              },
              svg: {
                height: "24px",
                width: "24px",
              },
            }}
            color="primary"
            onClick={handleSupportModal}
            role="link"
            type="button"
            aria-label="Client support"
            aria-controls="Client support"
          >
            <ReactSVG src="/icons/help.svg" />
            &nbsp;{t("Client support")}
          </Button>
        </Stack>
      </Drawer>
      <SupportModal
        show={showSupportModal}
        handleToggle={handleSupportModal}
        title="New client support request"
      />
    </Fragment>
  )
}

SideBar.displayName = "SideBar"

export default memo(SideBar)
