import {type PropsWithChildren} from "react"
import {Navigate} from "react-router-dom"
import {Stack} from "@mui/material"
import {decodeToken} from "react-jwt"
import {SideBar, Footer} from "../components/organisms"
import {useConfigProvider} from "../config"

type LayoutProps = {}

export const AppLayout: React.FC<PropsWithChildren<LayoutProps>> = (props) => {
  const {children} = props
  const {token} = useConfigProvider()
  const decoded = decodeToken<any>(token)
  const groups = decoded?.groups || []
  const hasAccess = groups.includes("MyControlRisks")

  if (!hasAccess) {
    return <Navigate to="/access-denied" replace={true} />
  }

  return (
    <Stack
      height="calc(100vh - 88px)"
      sx={{backgroundColor: "#FBFBFB"}}
      component="main"
    >
      <Stack flexDirection="row" component="main" sx={{height: "100%"}}>
        <SideBar />
        <Stack flexGrow={1} overflow="auto" height="100%">
          <Stack
            marginLeft={"56px"}
            flexGrow={1}
            minHeight="calc(100vh - 88px)"
          >
            {children}
          </Stack>
          <Stack position={"relative"} height={"56px"}>
            <Footer
              sx={{
                position: "absolute",
                width: "100%",
                zIndex: 102,
              }}
            />
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  )
}

AppLayout.displayName = "AppLayout"

export default AppLayout
