import {Skeleton} from "@mui/material"
import {useEffect, useState, type FC} from "react"

type Props = {
  file?: string | File | Blob
  fileType?: string
  setIsViewerLoaded: React.Dispatch<React.SetStateAction<boolean>>
}

const VideoViewer: FC<Props> = (props) => {
  const {file, fileType, setIsViewerLoaded} = props

  const [url, setUrl] = useState("")

  useEffect(() => {
    if (!file) return
    if (typeof file === "string") {
      setUrl(file)
      setIsViewerLoaded(true)
    } else {
      const tempUrl = URL.createObjectURL(file)
      setUrl(tempUrl)
      setIsViewerLoaded(true)
    }
  }, [file])

  if (!file || !fileType)
    return (
      <Skeleton
        variant="rectangular"
        height="400"
        width="100%"
        sx={{borderRadius: "0.5rem"}}
      />
    )

  return (
    <video
      controls
      controlsList="nodownload"
      disablePictureInPicture
      height="400"
      src={url}
      style={{
        height: "100%",
        width: "100%",
      }}
    />
  )
}

VideoViewer.displayName = "VideoViewer"

export default VideoViewer
