import React, {useEffect, useRef, ReactNode} from "react"
import {Stack} from "@mui/material"
import CloseOutlined from "@mui/icons-material/CloseOutlined"
import {NewButton} from "../../atoms"

interface FullScreenModalProps {
  children: ReactNode
  handleCloseFullScreen: () => void
  isFullScreen: boolean
  isFileType?: boolean
}

export const PresenterModeView: React.FC<FullScreenModalProps> = ({
  children,
  handleCloseFullScreen,
  isFullScreen,
  isFileType,
}) => {
  const modalRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    const handleFullScreenChange = () => {
      if (!document.fullscreenElement) {
        handleCloseFullScreen()
      }
    }

    document.addEventListener("fullscreenchange", handleFullScreenChange)

    if (isFullScreen && modalRef.current) {
      modalRef.current
        .requestFullscreen()
        .catch((err) =>
          console.error("Error attempting to enable full-screen mode:", err),
        )
    } else if (!isFullScreen && document.fullscreenElement) {
      document
        .exitFullscreen()
        .catch((err) =>
          console.error("Error attempting to disable full-screen mode:", err),
        )
    }

    return () => {
      document.removeEventListener("fullscreenchange", handleFullScreenChange)
    }
  }, [isFullScreen, handleCloseFullScreen])

  return (
    <Stack
      ref={modalRef}
      sx={{
        height: "100vh",
        width: "100vw",
        display: isFullScreen ? "block" : "none",
      }}
    >
      <Stack
        sx={{
          width: "100%",
          height: "100%",
          bgcolor: isFileType ? "#f8f9fa" : "background.paper",
          boxShadow: 24,
          pt: isFileType ? "20px" : "unset",
        }}
      >
        <Stack
          position={"absolute"}
          sx={{
            zIndex: 200,
            top: "7px",
            right: "15px",
          }}
        >
          <NewButton
            icon
            variant="filled"
            color="primary"
            size="medium"
            IconLeft={<CloseOutlined height="1.5rem" width="1.5rem" />}
            onClick={handleCloseFullScreen}
            sx={{
              minWidth: "unset",
            }}
          />
        </Stack>
        {children}
      </Stack>
    </Stack>
  )
}
