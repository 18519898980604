import {FC, useState} from "react"
import {useParams} from "react-router-dom"
import {useMutation, useQuery} from "@tanstack/react-query"
import {useForm, Controller} from "react-hook-form"
import {
  Box,
  Dialog,
  Typography,
  Stack,
  useTheme,
  IconButton,
} from "@mui/material"
import * as yup from "yup"
import {yupResolver} from "@hookform/resolvers/yup"
import DOMPurify from "dompurify"
import {Input, NewButton} from "../../"
import {projectsEndpoints} from "../../../services/api/projects"
import {notificationEndpoints} from "../../../services/api/notification"
import type {DeliverableModel, ProjectAssignmentModel} from "../../../types"
import type {Notification} from "../../../services/types/notification"
import {useConfigProvider} from "../../../config"
import {toast} from "react-toastify"
import {appInsights} from "../../../config/appInsights"
import {CloseIcon, ErrorIcon, SuccessIcon} from "../../../assets/icons"
import {deliverableEndpoints} from "../../../services/api/deliverables"
import {regexValidators} from "../../../helpers"
import {RichTextField} from "../../molecules/richTextEditor/RichTextField"
import filterEmojis from "../../../helpers/filterEmojis"
import {useTranslation} from "react-i18next"
const {stringIsLink, stringIsNotSQL, stringIsScript} = regexValidators()

const schema = yup.object({
  subject: yup
    .string()
    .required("Subject is required")
    .min(3, "Minimum 3 characters")
    .max(100, "Maximum 100 characters")
    .matches(stringIsLink, "Links are not allowed")
    .matches(stringIsScript, "Invalid text format")
    .matches(stringIsNotSQL, "Invalid text format"),
  description: yup
    .string()
    .required("Description is required")
    .min(3, "Minimum 3 characters")
    .max(2000, "Maximum 2000 characters")
    .matches(stringIsLink, "Links are not allowed")

    .matches(stringIsScript, "Invalid text format")
    .matches(stringIsNotSQL, "Invalid text format"),
})

type FormValues = yup.InferType<typeof schema>

type Props = {
  title?: string
  show: boolean
  handleToggle: () => void
  relatesToText?: string
}

const SupportModal: FC<Props> = (props) => {
  const {
    show,
    title = "New client support request",
    handleToggle,
    relatesToText,
  } = props

  const {getUserProjectById} = projectsEndpoints()
  const {getDeliverable} = deliverableEndpoints()
  const {sendClientSupportNotificationEmail} = notificationEndpoints()
  const {user} = useConfigProvider()
  const theme = useTheme()
  const {t} = useTranslation()
  const {id, folderId, dashboardId, fileId} = useParams<{
    id: string
    folderId: string
    dashboardId: string
    fileId: string
  }>()

  const [descriptionHtmlContent, setDescriptionHtmlContent] =
    useState<string>("")

  const {reset, control, handleSubmit} = useForm<FormValues>({
    resolver: yupResolver(schema),
    mode: "onBlur",
  })

  const projectQuery = useQuery<ProjectAssignmentModel>({
    queryKey: ["project", id],
    queryFn: () => getUserProjectById(id || ""),
    enabled: !!id && !dashboardId && !fileId,
  })

  const deliverableQuery = useQuery<DeliverableModel>({
    queryKey: ["getDeliverable", fileId || dashboardId],
    queryFn: () => getDeliverable(fileId || dashboardId || ""),
    enabled: !!fileId || !!dashboardId,
  })

  const sendNotificationMutation = useMutation<any, Error, Notification>({
    mutationFn: (payload) => sendClientSupportNotificationEmail(payload),
    mutationKey: ["sendNotification"],
  })

  const relatesTo = relatesToText
    ? relatesToText
    : fileId || dashboardId
    ? deliverableQuery.data?.deliverableName || "General"
    : id
    ? projectQuery.data?.projectAssignmentDisplayName ||
      projectQuery.data?.projectAssignmentName ||
      "General"
    : "General"

  const handleClose = () => {
    handleToggle()
    reset()
  }

  const handleSubmitForm = async (data: FormValues) => {
    const subject = DOMPurify.sanitize(data.subject)
    const filteredDescription = filterEmojis(descriptionHtmlContent)
    const description = DOMPurify.sanitize(filteredDescription || "")

    const notificationPayload = {
      notificationType: "Support-ToClientAndInternal",
      reference: relatesTo,
      subject: subject,
      htmlContent: description,
      user: {
        userFirstname: user.firstname,
        userEmail: user.email,
        userOwnername: user.firstname,
      },
    } satisfies Notification
    try {
      const notificationResponse = await sendNotificationMutation.mutateAsync(
        notificationPayload,
      )

      if (!!notificationResponse.status || !!notificationResponse.statusCode) {
        toast.error(
          "An unexpected error occurred. Please try refresh your page.",
          {
            autoClose: 3000,
            icon: ErrorIcon,
            toastId: "api-error-toast",
          },
        )
        return
      }
      appInsights.trackEvent({
        name: "CLIENT_SUPPORT",
        properties: {
          userId: user.userId,
          userEmail: user.email,
          name: `${user.firstname} ${user.lastname}`,
          userName: user.userName,
          relatesTo: relatesTo,
          id: id || "",
          folderId: folderId || "",
          dashboardId: dashboardId || "",
          fileId: fileId || "",
        },
      })
      toast.success(t("Successful! Your request has been submitted"), {
        autoClose: 3000,
        style: {
          borderLeftColor: "#58A733",
        },
        icon: SuccessIcon,
      })
      handleClose()
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <Dialog
      open={show}
      onClose={handleClose}
      component="form"
      onSubmit={handleSubmit(handleSubmitForm)}
      sx={{
        ".MuiDialog-paper": {
          maxWidth: "721px",
          marginTop: "40px",
          width: "712px",
          minWidth: 700,
          maxHeight: "100vh",
          bgcolor: "white",
          paddingBottom: "1rem",
          paddingTop: "14px",
          paddingX: "1.5rem",
          borderTop: "10px solid #003D50",
          borderRadius: "0.5rem",
          overflowY: "auto",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          gap: "1rem",
        },
      }}
    >
      <Stack
        sx={{width: "100%"}}
        component="header"
        direction="column"
        gap="1rem"
      >
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"end"}
        >
          <Typography
            fontSize="20px"
            lineHeight="1.875rem"
            letterSpacing="-0.034rem"
            fontWeight={600}
          >
            {t(title)}
          </Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Stack>
        <Box sx={{width: "100%", borderTop: "1px solid #CACDCD"}} />
      </Stack>
      <Stack
        sx={{width: "100%"}}
        component="main"
        direction="column"
        gap="0.25rem"
      >
        <Stack direction="row" gap="1rem" alignItems="flex-start">
          <Controller
            control={control}
            name="subject"
            render={({field, fieldState}) => (
              <Input
                label={t("Subject")}
                limit={100}
                required
                counter
                error={fieldState.error?.message}
                requiredStyle={{color: theme.palette.error.main}}
                formGroupProps={{
                  flex: 2,
                }}
                textFieldProps={{
                  placeholder: t("Type subject"),
                  fullWidth: true,
                  sx: {
                    ".MuiInputBase-formControl": {
                      height: "2.5rem",
                    },
                  },
                  InputProps: {sx: {fontSize: "14px"}},
                  ...field,
                }}
              />
            )}
          />
          <Box position="relative" sx={{flex: 1, maxWidth: "33%"}}>
            <Input
              aria-label="relates-to-input"
              textFieldProps={{
                id: "relatesTo",
                placeholder: "Type relates to",
                fullWidth: true,
                required: true,
                InputProps: {
                  value: t(relatesTo),
                  sx: {
                    fontSize: "14px",
                    ".MuiOutlinedInput-input.Mui-disabled": {
                      backgroundColor: "#BDBDBC",
                      color: "#242D35",
                      opacity: "unset",
                      "-webkit-text-fill-color": "#242D35",
                    },
                  },
                },
              }}
              formGroupProps={{}}
              label={t("Relates to")}
              required
              disabled
              ellipsis
            />
          </Box>
        </Stack>
        <Stack>
          <Controller
            control={control}
            name="description"
            render={({field, fieldState}) => (
              <RichTextField
                formError={
                  fieldState.error?.message ? t(fieldState.error?.message) : ""
                }
                {...field}
                onChange={(value) => {
                  setDescriptionHtmlContent(value.html)
                  field.onChange(value.text)
                }}
                placeholder={t("Tell us about your enquiry")}
                label={t("Description")}
                editorContainerStyle={{height: "289px"}}
              />
            )}
          />
        </Stack>
      </Stack>
      <Stack
        direction="row"
        gap="5px"
        component="footer"
        marginTop="0.25rem"
        justifyContent="flex-end"
        width="100%"
        px="8px"
      >
        <NewButton
          variant="outline"
          color="primary"
          size="medium"
          text={t("Cancel")}
          onClick={handleClose}
          sx={{
            width: "88px",
            backgroundColor: "white",
            color: "#053747",
            textDecoration: "underline",
            textUnderlineOffset: "5px",
            border: "unset",
            whiteSpace: "nowrap",
            "&:hover": {
              borderBottomWidth: "2px",
              bgcolor: "transparent",
              paddingTop: "2px",
              textDecoration: "underline",
            },
            "&:active": {
              backgroundColor: "transparent",
            },
          }}
        />
        <NewButton
          variant="filled"
          color="primary"
          size="medium"
          text={t("Submit")}
          type="submit"
          loading={
            sendNotificationMutation.isPending ||
            projectQuery.isLoading ||
            deliverableQuery.isLoading
          }
          sx={{
            width: "136px",
          }}
        />
      </Stack>
    </Dialog>
  )
}

SupportModal.displayName = "SupportModal"

export default SupportModal
