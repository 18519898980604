import {FC, useState} from "react"
import {Drawer, Stack, Typography} from "@mui/material"
import {CancelIcon, QuestionIcon} from "../../../assets/icons"
import {Tooltip} from "../../atoms"
import Dropzone from "../../molecules/dropzone/Dropzone"
import {ClientModel, PagedResult, ProjectAssignmentModel} from "../../../types"
import {UseQueryResult} from "@tanstack/react-query"
import {useTranslation} from "react-i18next"
import {ConfirmationDialog} from "../../molecules"

interface CancelModalProps {
  openCancelModal: boolean
  handleCancelModalClose: () => void
  confirmCancellationHandler: () => void
}

const CancelModal: FC<CancelModalProps> = ({
  openCancelModal,
  handleCancelModalClose,
  confirmCancellationHandler,
}: CancelModalProps) => {
  const {t} = useTranslation()
  return (
    <ConfirmationDialog
      showDialog={openCancelModal}
      handleClose={handleCancelModalClose}
      handleConfirm={confirmCancellationHandler}
      title={t("Cancel file uploads")}
      message={t(
        "All progress will be lost if you cancel now. Confirm you want to proceed?",
      )}
      closeText={t("Cancel")}
      confirmText={t("Confirm")}
    />
  )
}

interface DrawerProps {
  openDrawer: boolean
  onClose: () => void
  client?: PagedResult<ClientModel>
  project?: UseQueryResult<ProjectAssignmentModel, Error>
}

const UploadFileDrawer: FC<DrawerProps> = ({
  openDrawer,
  onClose,
  client,
  project,
}: DrawerProps) => {
  const [files, setFiles] = useState<File[]>([])
  const [openCancelModal, setOpenCancelModal] = useState<boolean>(false)
  const [uploadInProgress, setUploadInProgress] = useState<boolean>(false)
  const {t} = useTranslation()

  const handleCancelModalClose = () => {
    setOpenCancelModal(false)
  }

  const handleConfirmCancellation = () => {
    setFiles([])
    setOpenCancelModal(false)
    onClose()
  }
  return (
    <Drawer
      variant="temporary"
      anchor="right"
      open={openDrawer}
      onClose={() => false}
      PaperProps={{
        style: {
          width: 620,
          height: "calc(100% - 144px)",
          transform: `translateX(${openDrawer ? 0 : -240}px)`,
          transition: "transform 0.3s ease-in-out",
          backgroundColor: "white",
          marginTop: "88px",
        },
      }}
    >
      <CancelModal
        openCancelModal={openCancelModal}
        handleCancelModalClose={handleCancelModalClose}
        confirmCancellationHandler={handleConfirmCancellation}
      />

      <Stack height="100%" flexGrow={1}>
        <Stack height="100%">
          <Stack
            direction="row"
            justifyContent={"space-between"}
            alignItems={"center"}
            alignContent={"center"}
            sx={{padding: "16px"}}
          >
            <Typography fontWeight={600} fontSize={20} color={"#053747"}>
              {t("Upload files")} {"("}
              {files?.length > 0 ? files.length : 0}/10{")"}
            </Typography>
            <Stack
              sx={{cursor: "pointer"}}
              onClick={() =>
                uploadInProgress ? null : setOpenCancelModal(true)
              }
            >
              <CancelIcon fill={"#7A7A79"} />
            </Stack>
          </Stack>

          <Stack sx={{width: "100%", border: "1px solid #DEDEDE"}}></Stack>

          <Stack
            sx={{
              padding: "16px",
              height: "100%",
            }}
            spacing={1}
          >
            <Stack
              direction="row"
              alignContent={"center"}
              alignItems={"center"}
              sx={{paddingLeft: "8px", paddingRight: "8px"}}
            >
              <Typography fontWeight={400} fontSize={16} color="#053747">
                {t("Select files (up to 10 files at a time)")}
              </Typography>
              <Tooltip
                arrow={true}
                title={t(
                  "Supported formats: common image formats, Office documents, PDF documents. Max file size 35MB",
                )}
              >
                <Stack>
                  <QuestionIcon fill={"#6A6A69"} width={24} height={24} />
                </Stack>
              </Tooltip>
            </Stack>
            <Stack sx={{height: "100%"}}>
              <Dropzone
                files={files}
                setFiles={setFiles}
                setOpenCancelModal={setOpenCancelModal}
                client={client}
                projectQuery={project}
                forceCloseDrawer={handleConfirmCancellation}
                uploadInProgress={uploadInProgress}
                setUploadInProgress={setUploadInProgress}
              />
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Drawer>
  )
}

export default UploadFileDrawer
