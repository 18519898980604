export const iconMap = {
  csv: "/file-icons/csv.svg",
  docx: "/file-icons/docx.svg",
  doc: "/file-icons/doc.svg",
  // dot: "/file-icons/dot.svg",
  // dotx: "/file-icons/dotx.svg",
  // docm: "/file-icons/docm.svg",
  // dotm: "/file-icons/dotm.svg",
  xlsx: "/file-icons/xlsx.svg",
  // xls: "/file-icons/xls.svg",
  // xlt: "/file-icons/xlt.svg",
  // xlsm: "/file-icons/xlsm.svg",
  pdf: "/file-icons/pdf.svg",
  pptx: "/file-icons/pptx.svg",
  ppt: "/file-icons/ppt.svg",
  // pps: "/file-icons/pps.svg",
  // pptm: "/file-icons/pptm.svg",
  jpg: "/file-icons/jpg.svg",
  jpeg: "/file-icons/jpeg.svg",
  // jfif: "/file-icons/jfif.svg",
  png: "/file-icons/png.svg",
  // tif: "/file-icons/tif.svg",
  // tiff: "/file-icons/tiff.svg",
  // gif: "/file-icons/gif.svg",
  // bmp: "/file-icons/bmp.svg",
  // md: "/file-icons/md.svg",
  dashboards: "/file-icons/dashboards.svg",
  default: "/file-icons/defaultFile.svg",
}

export const getDeliverableExtension = (url: string): keyof typeof iconMap => {
  const extension = url.split(".").pop()?.toLowerCase() || ""
  return extension !== "" && iconMap[extension as keyof typeof iconMap]
    ? (extension as keyof typeof iconMap)
    : "default"
}
