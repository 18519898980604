// import {FoleonViewer} from "@cr/doc-viewer"
// import {CloseOutlined} from "@mui/icons-material"
import {Box, Skeleton, Stack, Typography, createTheme} from "@mui/material"
import {useQuery} from "@tanstack/react-query"
import {Fragment, useEffect, useState, type FC} from "react"
import {decodeToken} from "react-jwt"
import {/*useNavigate,*/ useParams} from "react-router-dom"
import {Breadcrumb /*, NewButton*/} from "../../components"
import {useConfigProvider} from "../../config"
import {deliverableEndpoints, projectsEndpoints} from "../../services"
import usePageStore from "../../stores/pageStore"
import type {
  DeliverableModel,
  FolderModel,
  ProjectAssignmentModel,
  Token,
} from "../../types"
import AccessDenied from "../AccessDenied/AccessDenied"

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 800,
      sm: 1200,
      md: 1500,
      lg: 1900,
      xl: 2000,
    },
  },
})

const ViewWebReport: FC = () => {
  const {id, folderId, reportId} = useParams<{
    id: string
    folderId: string
    reportId: string
  }>()
  // const navigate = useNavigate()
  const {basename, token, faToken, apimKey, serverUrl} = useConfigProvider()
  const decoded = decodeToken<Token>(token)
  const [isCookie, setIsCookie] = useState<boolean>(false)

  const {getUserProjectById, getFolderById} = projectsEndpoints()
  const {getDeliverable} = deliverableEndpoints()

  const projectQuery = useQuery<ProjectAssignmentModel>({
    queryKey: ["getProject", id],
    queryFn: () => getUserProjectById(id || ""),
  })

  const folderQuery = useQuery<FolderModel>({
    queryKey: ["getFolder", id, folderId],
    queryFn: () => {
      return getFolderById(id || "", folderId || "")
    },
  })

  const deliverableQuery = useQuery<DeliverableModel>({
    queryKey: ["getDeliverable", reportId],
    queryFn: () => getDeliverable(reportId || ""),
  })

  useEffect(() => {
    if (!isCookie && decoded && serverUrl) {
      document.cookie = `token=${token}; Domain=.controlrisks.com; path=/; Max-Age=3600000; SameSite=None; Secure;`
      document.cookie = `jwt=${token}; Domain=.controlrisks.com; path=/; Max-Age=3600000; SameSite=None; Secure;`
      document.cookie = `faToken=${faToken}; Domain=.controlrisks.com; path=/; Max-Age=3600000; SameSite=None; Secure`
      document.cookie = `apimKey=${apimKey}; Domain=.controlrisks.com; path=/; Max-Age=3600000; SameSite=None; Secure`
      setIsCookie(true)
    }

    return () => {
      document.cookie = `token=; path=/; expires=Thu, 01 Jan 1970 00:00:00 UTC; SameSite=None; Secure`
      document.cookie = `jwt=; path=/; expires=Thu, 01 Jan 1970 00:00:00 UTC; SameSite=None; Secure`
      document.cookie = `faToken=; path=/; expires=Thu, 01 Jan 1970 00:00:00 UTC; SameSite=None; Secure`
      document.cookie = `apimKey=; path=/; expires=Thu, 01 Jan 1970 00:00:00 UTC; SameSite=None; Secure`
    }
  }, [token, faToken, apimKey])

  // const handleClose = () => {
  //   navigate(`${basename}/${id}/folders/${folderId}`, {replace: true})
  // }

  const setCurrentPage = usePageStore((state) => state.setCurrentPage)
  useEffect(() => {
    setCurrentPage(deliverableQuery.data?.deliverableName || "General")
  }, [deliverableQuery.data?.deliverableName])

  useEffect(() => {
    const footer = document.getElementById("footer")
    if (footer) {
      footer.style.display = "none"
    }

    return () => {
      if (footer) {
        footer.style.display = "flex"
      }
    }
  }, [])

  if (deliverableQuery?.data?.hasOwnProperty("title")) {
    return <AccessDenied resource="deliverable" />
  }

  if (deliverableQuery?.data?.expiryDate) {
    const deliverableExpiryDate = Date.parse(
      deliverableQuery?.data?.expiryDate
        ? deliverableQuery?.data?.expiryDate
        : "",
    )
    const currentDate = Date.parse(new Date().toISOString())

    if (currentDate > deliverableExpiryDate) {
      return (
        <AccessDenied
          resource="deliverable"
          resourceName={deliverableQuery?.data?.deliverableName}
        />
      )
    }
  }

  if (
    (!deliverableQuery.data?.enabled &&
      !deliverableQuery.isLoading &&
      !deliverableQuery.isFetching &&
      !deliverableQuery.isPending) ||
    (!projectQuery.data?.enabled &&
      !projectQuery.isLoading &&
      !projectQuery.isFetching &&
      !projectQuery.isPending) ||
    (!folderQuery.data?.isEnabled &&
      !folderQuery.isLoading &&
      !folderQuery.isFetching &&
      !folderQuery.isPending)
  ) {
    return (
      <AccessDenied
        resource="deliverable"
        resourceName={deliverableQuery?.data?.deliverableName}
      />
    )
  }

  if (
    (folderId !== deliverableQuery.data?.folder?.folderId &&
      !deliverableQuery.isLoading &&
      !deliverableQuery.isFetching &&
      !deliverableQuery.isPending) ||
    (id !== deliverableQuery.data?.projectAssignmentId &&
      !deliverableQuery.isLoading &&
      !deliverableQuery.isFetching &&
      !deliverableQuery.isPending) ||
    !isCookie
  ) {
    return (
      <AccessDenied
        resource="deliverable"
        resourceName={deliverableQuery?.data?.deliverableName}
      />
    )
  }

  return (
    <Box
      component="main"
      paddingX="2rem"
      paddingTop="1rem"
      paddingBottom="3rem"
      width="100%"
    >
      <Stack direction="column" gap="0.5rem">
        <Breadcrumb
          links={[
            {
              label: "Projects",
              href: `${basename}`,
            },
            {
              label:
                projectQuery.data?.projectAssignmentDisplayName ||
                projectQuery.data?.projectAssignmentName ||
                "",
              href: `${basename}/${id}`,
              active: false,
              loading: projectQuery.isLoading,
            },
            {
              label: folderQuery.data?.folderName || "",
              href: `${basename}/${id}/folders/${folderId}`,
              active: false,
              loading: folderQuery.isLoading,
            },
            {
              label: deliverableQuery.data?.deliverableName || "",
              href: `${basename}/${id}/folders/${folderId}/web-report/${reportId}`,
              active: true,
              loading: deliverableQuery.isLoading,
            },
          ]}
        />
        <Stack direction="column" gap="0.5rem" component="section">
          <Stack
            direction="row"
            gap="0.5rem"
            justifyContent="space-between"
            component="header"
          >
            {projectQuery.isLoading ? (
              <Skeleton
                variant="text"
                width="20%"
                height="2rem"
                sx={{borderRadius: "0.5rem"}}
              />
            ) : (
              <Typography
                color="#242D35"
                fontWeight="600"
                fontSize="1rem"
                lineHeight="1.25rem"
              >
                {deliverableQuery.data?.deliverableName || ""}
              </Typography>
            )}
          </Stack>
          <Box
            component="hr"
            sx={{
              border: 0,
              borderTopWidth: "1px",
              borderTopStyle: "solid",
              borderTopColor: "#BDBDBC",
            }}
          />
          <Box
            sx={{
              width: "calc(100vw - 56px)",
              height: "100vh",
              position: "fixed",
              top: 0,
              right: 0,
              zIndex: 99,
              backgroundColor: "rgba(126, 128, 129, 0.3)",
              backdropFilter: "blur(5px)",
            }}
          />
          <Box
            sx={{
              backgroundColor: "#FFFFFF",
              boxShadow:
                "0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);",
              borderRadius: "0.5rem",
              padding: "2.5rem",
              position: "fixed",
              zIndex: 100,
              [theme.breakpoints.up("md")]: {
                height: "490px",
              },
              [theme.breakpoints.up("lg")]: {
                height: "710px",
              },
              height: "100vh",
              width: "calc(100vw - 56px)",
              top: 0,
              right: 0,
            }}
          >
            {deliverableQuery.isLoading ? (
              <Skeleton
                variant="rectangular"
                height="calc(100vh - 88px)"
                width="calc(100vw - 56px)"
                sx={{
                  position: "fixed",
                  top: 88,
                  right: 0,
                  zIndex: 9998,
                  height: "calc(100vh - 88px)",
                  width: "calc(100vw - 56px)",
                }}
              />
            ) : (
              <Fragment>
                {/* <NewButton
                  icon
                  variant="filled"
                  color="primary"
                  size="medium"
                  IconLeft={<CloseOutlined height="1.5rem" width="1.5rem" />}
                  onClick={handleClose}
                  sx={{
                    position: "fixed",
                    top: "98px",
                    left: "80px",
                    zIndex: 9999,
                    minWidth: "unset",
                  }}
                />
                <FoleonViewer
                  title="Web report"
                  aria-label="Web report"
                  width="100%"
                  height="702"
                  src={`${serverUrl}/webreport/${reportId}/index.html`}
                  sandbox="allow-scripts allow-same-origin allow-popups"
                  referrerPolicy="no-referrer"
                  style={{
                    border: "none",
                    height: "calc(100vh - 88px)",
                    width: "calc(100vw - 56px)",
                    position: "fixed",
                    top: 88,
                    right: 0,
                    zIndex: 9998,
                    backgroundColor: "#FFFFFF",
                  }}
                /> */}
              </Fragment>
            )}
          </Box>
        </Stack>
      </Stack>
    </Box>
  )
}

ViewWebReport.displayName = "ViewWebReport"
export default ViewWebReport
