import { Box, Modal, Stack, Typography, useTheme } from "@mui/material";
import {useMutation} from "@tanstack/react-query"
import { useRef, useState, type FC } from "react";
import { decodeToken } from "react-jwt";
import {useSessionStorage} from "usehooks-ts"
import { useConfigProvider } from "../../../config";
import {StorageData} from "../../../hooks/useStorage"
import {accountEndpoints} from "../../../services"
import {Token} from "../../../types"
import { NewButton, Tooltip } from "../../atoms";
import {TCs} from "./TCs"
import {useTranslation} from "react-i18next"

type Props = {
  isOpen: boolean
  onClose: () => void
}

export const TermsAndConditionsModal: FC<Props> = (props) => {
  const {isOpen, onClose} = props
  const {acceptTermsAndConditions} = accountEndpoints()

  const theme = useTheme()
  const {token} = useConfigProvider()
  const {t} = useTranslation()

  const decoded = decodeToken<Token>(token)
  const email = decoded?.email
  const [accepted, setAccepted] = useSessionStorage<boolean | undefined>(
    StorageData.acceptedTermsAndConditions,
    undefined,
  )

  const acceptedTermsAndConditions =
    !!decoded?.acceptedTermsAndConditions || !!accepted || false
  const [open, setOpen] = useState<boolean>(!acceptedTermsAndConditions)
  const [enableButton, setEnableButton] = useState<boolean>(false)
  const containerRef = useRef<HTMLDivElement>(null)

  const acceptTermsAndConditionsMutation = useMutation({
    mutationFn: () => acceptTermsAndConditions(email || ""),
    onSuccess: () => {
      setAccepted(true)
      setOpen(false)
      onClose()
      setEnableButton(false)
      containerRef.current?.scrollTo(0, 0)
    },
  })

  const handleScroll = () => {
    if (containerRef.current) {
      if (
        containerRef.current.scrollTop >=
        containerRef.current.scrollHeight - containerRef.current.clientHeight
      ) {
        setEnableButton(true)
      }
    }
  }

  const handleClose = () => {
    if (!acceptedTermsAndConditions) {
      const event = new CustomEvent("logout")
      window.dispatchEvent(event)
      return
    }
    setOpen(false)
    onClose()
    setEnableButton(false)
    containerRef.current?.scrollTo(0, 0)
  }

  const handleAcceptTerms = async () => {
    if (acceptedTermsAndConditions) {
      handleClose()
      return
    }
    if (email) {
      await acceptTermsAndConditionsMutation.mutateAsync()
    }
  }

  return (
    <Modal
      open={open || isOpen}
      aria-labelledby="terms-and-conditions-modal"
      aria-describedby="terms-and-conditions"
      sx={{
        "&.MuiModal-root": {
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          outline: "none",
        },
      }}
    >
      <Stack width="712px" bgcolor="white" gap="16px" sx={{outline: "none"}}>
        <Stack
          borderTop={`7px solid ${theme.palette.primary.main}`}
          borderBottom="1px solid #dedede"
          component="header"
        >
          <Typography
            fontSize="20px"
            fontWeight={600}
            color="#242d35"
            lineHeight="1.2"
            paddingX="32px"
            paddingTop="17px"
            paddingBottom="20px"
          >
            {t("Terms and conditions")}
          </Typography>
        </Stack>
        <Stack
          height="460px"
          maxHeight="100vh"
          component="section"
          paddingX="32px"
          overflow="auto"
          onScroll={handleScroll}
          ref={containerRef}
        >
          <TCs />
        </Stack>
        <Stack
          component="footer"
          flexDirection="row"
          alignItems="center"
          justifyContent="end"
          gap="24px"
          paddingX="32px"
          paddingBottom="24px"
          paddingTop="16px"
          borderTop="1px solid #dedede"
        >
          {isOpen ? (
            <NewButton
              text={t("Close")}
              onClick={handleClose}
              variant="filled"
              color="primary"
              size="medium"
              sx={{
                width: "136px",
                minWidth: "136px",
              }}
            />
          ) : (
            <NewButton
              text={t("Cancel")}
              onClick={handleClose}
              variant="underline"
              color="primary"
              size="medium"
              sx={{
                paddingX: "0",
                paddingBottom: "0",
                borderRadius: "0",
              }}
            />
          )}
          {isOpen ? null : enableButton ? (
            <NewButton
              text={t("Accept terms")}
              onClick={handleAcceptTerms}
              disabled={
                !enableButton || acceptTermsAndConditionsMutation.isPending
              }
              variant="filled"
              color="primary"
              size="medium"
            />
          ) : (
            <Tooltip
              title={t("Please read the full terms and conditions")}
              placement="top"
              arrow
              sx={{
                "& .MuiTooltip-tooltip": {
                  paddingX: "12px",
                  paddingY: "6px",
                  textAlign: "left",
                  maxWidth: "228px",
                },
              }}
            >
              <Box display="block">
                <NewButton
                  text={t("Accept terms")}
                  onClick={handleAcceptTerms}
                  disabled={
                    !enableButton || acceptTermsAndConditionsMutation.isPending
                  }
                  variant="filled"
                  color="primary"
                  size="medium"
                />
              </Box>
            </Tooltip>
          )}
        </Stack>
      </Stack>
    </Modal>
  )
}

TermsAndConditionsModal.displayName = "TermsAndConditionsModal";

export default TermsAndConditionsModal;
