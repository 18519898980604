import {type FC} from "react"
import {useNavigate} from "react-router-dom"
import {Box, Stack, Typography, useTheme} from "@mui/material"
import {ReactSVG} from "react-svg"
import {Tooltip} from "../../atoms"
import {DashboardIcon, FileIcon} from "../../../assets/icons"
import type {FolderModel} from "../../../types"
import {TextWithTooltip} from "../textWithTooltip"
import {useTranslation} from "react-i18next"

type Props = {
  folders: FolderModel[]
}
const FoldersGridView: FC<Props> = (props) => {
  const {folders} = props
  const theme = useTheme()
  const navigate = useNavigate()
  const {t} = useTranslation()

  return (
    <Stack
      direction="row"
      gap="0.625rem"
      flexWrap="wrap"
      mb={2}
      component="section"
    >
      {folders.map((folder) => (
        <Stack
          key={folder.folderId}
          direction="column"
          borderRadius="0.5rem"
          padding="1rem"
          gap="0.5rem"
          onClick={() => navigate(`folders/${folder.folderId}`)}
          sx={{
            height: "100px",
            width: "264px",
            backgroundColor: theme.palette.common.white,
            borderWidth: "1px",
            borderStyle: "solid",
            borderColor: "#EEEEEE",
            transition: "all 0.2s ease-in-out",
            textDecoration: "none",

            "article.grid-folder-icon >div": {
              "&:nth-of-type(1)": {
                position: "absolute",
                opacity: 1,
                transition: "all 0.2s ease-in-out",
                fill: "#003D50",
              },
              "&:nth-of-type(2)": {
                position: "absolute",
                opacity: 0,
                transition: "all 0.2s ease-in-out",
                fill: "#003D50",
              },
            },
            "&:hover": {
              borderColor: "#375F6C",
              cursor: "pointer",

              "article.grid-folder-icon >div": {
                "&:nth-of-type(1)": {
                  opacity: 0,
                  fill: "#003D50",
                },
                "&:nth-of-type(2)": {
                  opacity: 1,
                  fill: "#003D50",
                },
              },
            },
          }}
        >
          <Stack
            direction="row"
            gap="0.5rem"
            sx={{
              maxWidth: "100%",
              wordBreak: "break-word",
              svg: {
                width: "1.25rem",
                height: "1.25rem",
                fill: theme.palette.primary.main,

                path: {
                  fill: "inherit",
                },
              },
            }}
          >
            <Box
              component="article"
              height="1.25rem"
              width="1.25rem"
              position="relative"
              className="grid-folder-icon"
              sx={{
                flexShrink: 0,
              }}
            >
              <ReactSVG
                src="/icons/folder.svg"
                height="1.25rem"
                width="1.25rem"
              />
              <ReactSVG
                src="/icons/folder-selected.svg"
                height="1.25rem"
                width="1.25rem"
              />
            </Box>
            <TextWithTooltip
              text={folder.folderName}
              limit={45}
              includeEllipsis={false}
              textStyle={{
                fontSize: "0.875rem",
                fontWeight: 400,
                lineHeight: "1.25rem",
                color: "#242D35",
              }}
              tooltipProps={{
                placement: "top",
                arrow: true,
              }}
            />
          </Stack>
          <Stack
            direction="row"
            gap="1.5rem"
            alignItems="center"
            marginTop="auto"
          >
            <Stack direction="row" gap="0.25rem" alignItems="center">
              <Tooltip title={t("Dashboards")} arrow placement="right">
                <Box>
                  <DashboardIcon />
                </Box>
              </Tooltip>
              <Typography
                fontSize="0.875rem"
                color="#7E8081"
                lineHeight="1.192rem"
              >
                {folder.totalUrlDeliverables}
              </Typography>
            </Stack>
            <Stack direction="row" gap="0.25rem" alignItems="center">
              <Tooltip title={t("Files")} arrow placement="right">
                <Box>
                  <FileIcon />
                </Box>
              </Tooltip>
              <Typography
                fontSize="0.875rem"
                color="#7E8081"
                lineHeight="1.192rem"
              >
                {folder.totalFilesDeliverables}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      ))}
    </Stack>
  )
}

export default FoldersGridView
