import {
  useState,
  type FC,
  useEffect,
  useRef,
  ReactElement,
  Fragment,
  useMemo,
} from "react"
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Skeleton,
  Stack,
  TableCell,
  TableRow,
  Typography,
  Button,
  IconButton,
  CircularProgress,
  //useTheme,
} from "@mui/material"
import moment from "moment"
import {
  UseQueryResult,
  useQuery,
  useMutation,
  useQueryClient,
  useQueries,
  QueryClient,
} from "@tanstack/react-query"
import {useNavigate, useParams} from "react-router-dom"
import {ReactSVG} from "react-svg"
import {projectsEndpoints} from "../../services/api/projects"
import {LeftPanelLayout} from "../../components/organisms/LeftPanelLayout/LeftPanelLayout"
import ProjectSummary from "../../components/organisms/LeftPanelLayout/Summary/ProjectSummary"
import {
  Breadcrumb,
  ChecklistDrawer,
  ConfirmationDialog,
  FoldersGridView,
  FoldersListView,
  NewButton,
  Popover,
  SearchBarr,
  Table,
  TextWithTooltip,
  Tooltip,
  type TableHeadingProps,
} from "../../components"
import type {
  ClientModel,
  DeliverableModel,
  DeliverableStatusQuery,
  FolderModel,
  PagedResult,
  ProjectAssignmentModel,
} from "../../types"
import AccessDenied from "../AccessDenied/AccessDenied"
import ErrorScreen from "../Error/Error"
import usePageStore from "../../stores/pageStore"
import DeliverablesGridView from "../../components/molecules/DeliverablesGridView/DeliverablesGridView"
import {deliverableEndpoints} from "../../services/api/deliverables"
import {SortOptions} from "../../types/SortOptions"
import {DeliverableStatus} from "../../types/enums/DeliverableStatus"
import {
  ArrowDropDownIcon,
  BinIcon,
  DownloadIcon,
  //LoadingIcon,
  WarningIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  FolderIcon,
  UploadIcon,
  PlusIcon,
} from "../../assets/icons"
import {dateFormat, getDeliverableExtension, iconMap} from "../../helpers"
import UploadFileDrawer from "../../components/organisms/UploadFileDrawer/UploadFileDrawer"
import {contentTypeMap} from "../ViewFile/utils"
import {useConfigProvider} from "../../config"
import {decodeToken} from "react-jwt"
import {Token} from "../../types/Token"
import {utilitiesEndpoints} from "../../services/api/utilities"
import {FabricEvent} from "../../services/types/event"
import {appInsights} from "../../config/appInsights"
import {useTranslation} from "react-i18next"

const limit = 16
const headings = [
  {
    name: "Name",
    field: "folderName",
    isSortable: true,
    sx: {
      width: "80%",
    },
  },
  {
    name: "Deliverable type",
    field: "",
    isSortable: false,
    sx: {
      maxWidth: "250px",
    },
  },
] satisfies TableHeadingProps[]

const guidRegex = new RegExp(
  /^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$/, // FOR TESTING, WILL REMOVE LATER
)

const Loader: FC<{viewMode: string}> = ({viewMode}: {viewMode: string}) => {
  if (viewMode === "grid") {
    return (
      <Stack direction="row" gap="0.625rem" flexWrap="wrap">
        {Array(6)
          .fill(0)
          .map((_, index) => (
            <Skeleton
              key={index}
              variant="rectangular"
              width="264px"
              height="100px"
              sx={{
                borderRadius: "0.5rem",
              }}
            />
          ))}
      </Stack>
    )
  } else if (viewMode === "list") {
    return (
      <Table
        headings={headings}
        style={{
          paddingTop: 0,
        }}
        tableProps={{
          stickyHeader: true,
        }}
        sortState={{
          sortBy: "folderName",
          setSortBy: () => {},
        }}
        tableContainerProps={{
          sx: {
            boxShadow: "none",
            borderRadius: 0,
            padding: "0",
            boxSizing: "border-box",
            maxHeight: "604px",
            overflow: "auto",
            backgroundColor: "transparent",
          },
        }}
      >
        {Array(6)
          .fill(0)
          .map((_, index) => (
            <TableRow
              key={index}
              sx={{
                backgroundColor: "#FFFFFF",
                transition: "all 0.2s ease-in-out",
                cursor: "pointer",
                "&:hover": {
                  backgroundColor: "#EEEEEE",
                },
              }}
            >
              {headings.map((__, j) => (
                <TableCell
                  key={j}
                  sx={{
                    fontSize: "0.875rem",
                    fontWeight: 400,
                    color: "#1A1A1A",
                    lineHeight: "1.192rem",
                    padding: "0.75rem 1rem",
                    height: "2.5rem",
                  }}
                >
                  <Skeleton variant="rectangular" width="100%" height={15} />
                </TableCell>
              ))}
            </TableRow>
          ))}
      </Table>
    )
  }
}

const NoData: FC<{}> = ({}: {}) => {
  return (
    <Stack justifyContent="center" alignItems="center">
      <Typography
        component="p"
        textAlign="center"
        color="#595958"
        fontSize="0.875rem"
        fontWeight="400"
        lineHeight="1.25rem"
      >
        You have no folders allocated to you.
      </Typography>
    </Stack>
  )
}

const NoMatchesFound: FC<{}> = ({}: {}) => {
  return (
    <Stack
      component="section"
      direction="row"
      alignItems="center"
      justifyContent="center"
      sx={{height: "100px", width: "100%"}}
    >
      <Typography
        fontSize="1rem"
        lineHeight="1.875rem"
        letterSpacing="-1.7%"
        color={"black"}
        textAlign="center"
      >
        No matches found
      </Typography>
    </Stack>
  )
}

interface DeliverablesTableDisplayProps {
  yourUploadsList: DeliverableModel[] | undefined
  tableSortBy: string
  setTableSortBy: any
  handleReloadData: () => void
  project?: ProjectAssignmentModel
  client?: ClientModel
}

const DeliverablesTableDisplay: FC<DeliverablesTableDisplayProps> = ({
  yourUploadsList,
  tableSortBy,
  setTableSortBy,
  handleReloadData,
  project,
  client,
}) => {
  const yourUploadsHeadings = [
    {
      name: "Name",
      field: "name",
      isSortable: true,
      sx: {width: "20%", color: "#242D35", fontWeight: 700, fontSize: "14px"},
    },
    {
      name: "Description",
      field: "description",
      isSortable: true,
      wide: true,
      sx: {width: "40%", color: "#242D35", fontWeight: 600, fontSize: "14px"},
    },
    {
      name: "Uploaded by",
      field: "createdBy",
      isSortable: true,
      sx: {width: "20%", color: "#242D35", fontWeight: 600, fontSize: "14px"},
    },
    {
      name: "Uploaded",
      field: "publishedDate",
      isSortable: true,
      sx: {width: "20%", color: "#242D35", fontWeight: 600, fontSize: "14px"},
    },
  ] satisfies TableHeadingProps[]

  const navigate = useNavigate()
  const {id} = useParams<{id: string}>()
  const {token, basename} = useConfigProvider()
  const decoded = decodeToken<Token>(token)
  const queryClient = useQueryClient()
  const [deliverableToDelete, setDeliverableToDelete] = useState<
    string | undefined
  >(undefined)
  const showDeleteDialog = Boolean(deliverableToDelete)
  const {downloadDeliverableFile, deleteDeliverable} = deliverableEndpoints()
  const {sendFabricEvent} = utilitiesEndpoints()

  const downloadFileMutation = useMutation<File, Error, DeliverableModel>({
    mutationKey: ["downloadFile"],
    mutationFn: async (deliverable: DeliverableModel) => {
      const fileId = deliverable.id
      const deliverableName = deliverable.deliverableName
      const fileName = deliverableName
        ? deliverableName
            .split("/")
            .pop()
            ?.match(/.{1,30}/g)
            ?.join("\n")
        : "defaultFileName"
      const extension = deliverable.deliverableSource?.toLowerCase()
      const contentType = extension
        ? contentTypeMap[extension as keyof typeof contentTypeMap]
        : null

      return (
        (await downloadDeliverableFile(
          fileId || "",
          fileName || "",
          contentType,
        )) || Promise.reject(new Error("File not found"))
      )
    },
  })

  const deleteDeliverableMutation = useMutation<
    DeliverableModel,
    Error,
    string
  >({
    mutationKey: ["deleteDeliverable"],
    mutationFn: async (id) => await deleteDeliverable(id),
    onSuccess: () => handleReloadData(),
  })

  const fabricEventMutation = useMutation<any, Error, FabricEvent>({
    mutationFn: (payload) => sendFabricEvent(payload),
    mutationKey: ["sendNotification"],
  })

  const handleNavigate = (deliverable: DeliverableModel) => {
    if (deliverable.deliverableType === "dashboard") {
      navigate(
        `${basename}/${id}/folders/${deliverable.folder.folderId}/dashboard/${deliverable.id}`,
      )
    } else {
      navigate(
        `${basename}/${id}/folders/${deliverable.folder.folderId}/file/${deliverable.id}`,
      )
    }
  }

  const handleDownload = async (deliverable: DeliverableModel) => {
    const result = await downloadFileMutation.mutateAsync(deliverable)
    if (result) {
      const url = URL.createObjectURL(result)
      const link = document.createElement("a")
      link.href = url
      link.download = deliverable.deliverableName
      link.click()
      URL.revokeObjectURL(url)
      link.remove()
      //send logs
      appInsights.trackEvent({
        name: "DOWNLOAD_FILE",
        properties: {
          id: deliverable.id,
          projectName:
            project?.projectAssignmentName ??
            project?.projectAssignmentDisplayName,
          projectId: project?.id,
          fileName: deliverable.deliverableName,
        },
      })
      // send event to fabric
      const eventPayload = {
        type: "download_file",
        targetId: deliverable?.id || "",
        targetName: deliverable?.deliverableName || "",
      } satisfies FabricEvent
      await fabricEventMutation.mutateAsync(eventPayload)
    }
  }

  const handleCloseDeleteDialog = () => {
    setDeliverableToDelete(undefined)
  }

  const handleDeleteDeliverable = async () => {
    if (!deliverableToDelete || decoded?.companyId === "001") return
    const result = await deleteDeliverableMutation.mutateAsync(
      deliverableToDelete,
    )
    if (result && result.id) {
      handleCloseDeleteDialog()
      handleReloadData()
      //send logs
      appInsights.trackEvent({
        name: "DELETE_FILE",
        properties: {
          id: result.id,
          projectName:
            project?.projectAssignmentName ??
            project?.projectAssignmentDisplayName,
          projectId: project?.id,
          fileName: result.deliverableName,
          userEmail: decoded?.email,
          clientName: client?.clientName,
        },
      })
      queryClient.invalidateQueries({
        queryKey: ["deliverables", id],
        exact: true,
      })
      queryClient.invalidateQueries({
        queryKey: ["getFoldersList", id],
        exact: true,
      })
      queryClient.invalidateQueries({
        queryKey: ["getYourUploadsDeliverables"],
        exact: true,
      })
    }
  }

  const deliverableIcon = (deliverable: DeliverableModel) => {
    if (deliverable.status === DeliverableStatus.CLEAN) {
      return (
        <ReactSVG
          src={
            deliverable.deliverableType === "dashboard"
              ? "/icons/file-icons/dashboards.svg"
              : "/icons" +
                iconMap[
                  getDeliverableExtension(deliverable.deliverableUrl || "")
                ]
          }
        />
      )
    } else if (deliverable.status === DeliverableStatus.IN_PROGRESS) {
      return (
        <Tooltip title=" File scanning. Please wait " arrow>
          <Stack>
            <CircularProgress size={"16px"} sx={{color: "#595958"}} />
          </Stack>
        </Tooltip>
      )
    } else if (deliverable.status === DeliverableStatus.QUARANTINE) {
      return (
        <Tooltip title="File quarantined. Delete to reupload" arrow>
          <Stack>
            <WarningIcon fill="#DB0D00" />
          </Stack>
        </Tooltip>
      )
    }
  }

  return (
    <Fragment>
      <ConfirmationDialog
        showDialog={showDeleteDialog}
        handleClose={handleCloseDeleteDialog}
        handleConfirm={handleDeleteDeliverable}
        title="Delete file"
        message="This file will be permanently deleted. Confirm you want to proceed?"
        disabled={deleteDeliverableMutation.isPending}
      />
      <Table
        headings={yourUploadsHeadings}
        sortState={{sortBy: tableSortBy, setSortBy: setTableSortBy}}
        tableProps={{
          stickyHeader: true,
        }}
        noneFound={"No uploads found"}
        tableContainerProps={{
          sx: {
            boxShadow: "none",
            borderRadius: 0,
            padding: "0",
            boxSizing: "border-box",
            maxHeight: "400px",
            overflow: "auto",
            backgroundColor: "transparent",
            paddingRight: "0.25rem",
            overflowY: "scroll",

            "&:hover": {
              paddingRight: "0.25rem",
            },
          },
        }}
      >
        {yourUploadsList?.map(
          (deliverable: DeliverableModel, index: number) => (
            <TableRow
              key={deliverable.id}
              sx={{
                backgroundColor: "#FBFBFB",
                transition: "all 0.2s ease-in-out",
                cursor:
                  deliverable.status === DeliverableStatus.IN_PROGRESS
                    ? "not-allowed"
                    : deliverable.status === DeliverableStatus.QUARANTINE
                    ? "auto"
                    : "pointer",
                "&:hover": {
                  backgroundColor: "#EEEEEE",

                  ".deliverable-actions": {
                    opacity: 1,
                  },
                },
              }}
              onClick={() =>
                deliverable.status !== DeliverableStatus.CLEAN
                  ? null
                  : handleNavigate(deliverable)
              }
            >
              <TableCell
                sx={{
                  width: "20%",
                  fontSize: "0.875rem",
                  fontWeight: 400,
                  color:
                    deliverable.status !== DeliverableStatus.CLEAN
                      ? "#7A7A79"
                      : "#242D35",
                  lineHeight: "1.192rem",
                  height: "2.5rem",
                  svg: {
                    maxHeight: "20px",
                  },
                  padding: 0,
                }}
              >
                <Stack
                  sx={{
                    height: "100%",
                    borderLeft:
                      deliverable.status === DeliverableStatus.IN_PROGRESS
                        ? "2px solid #CDCDCD"
                        : deliverable.status === DeliverableStatus.QUARANTINE
                        ? "2px solid #DB0D00"
                        : "none",
                    padding: "0.75rem 1rem",
                  }}
                  direction="row"
                >
                  <Stack direction="row" spacing={1}>
                    <Stack>{deliverableIcon(deliverable)}</Stack>
                    <Stack direction="row">
                      {deliverable.deliverableName.length >= 33 ? (
                        <TextWithTooltip
                          tooltipProps={{
                            arrow: true,
                            placement: "bottom-start",
                          }}
                          tooltipStyle={{
                            "& .MuiTooltip-arrow": {
                              // move arrow to the left
                              //left: "10px !important",
                              transform: "none !important",
                            },
                          }}
                          text={deliverable.deliverableName}
                          textStyle={{
                            fontSize: "0.875rem",
                            fontWeight: 400,
                            color:
                              deliverable.status !== DeliverableStatus.CLEAN
                                ? "#7A7A79"
                                : "#242D35",
                            lineHeight: "1.192rem",
                          }}
                          limit={33}
                        />
                      ) : (
                        deliverable.deliverableName
                      )}
                    </Stack>
                  </Stack>
                </Stack>
              </TableCell>
              <TableCell
                sx={{
                  width: "40%",
                  fontSize: "0.875rem",
                  fontWeight: 400,
                  color:
                    deliverable.status !== DeliverableStatus.CLEAN
                      ? "#7A7A79"
                      : "#242D35",
                  lineHeight: "1.192rem",
                  padding: "0.75rem 1rem",
                  height: "2.5rem",
                }}
              >
                <TextWithTooltip
                  tooltipProps={{arrow: true, placement: "bottom"}}
                  text={deliverable.deliverableDescription}
                  limit={60}
                  textStyle={{
                    fontSize: "0.875rem",
                    fontWeight: 400,
                    color:
                      deliverable.status !== DeliverableStatus.CLEAN
                        ? "#7A7A79"
                        : "#242D35",
                    lineHeight: "1.192rem",
                  }}
                />
              </TableCell>
              <TableCell
                sx={{
                  width: "20%",
                  fontSize: "0.875rem",
                  fontWeight: 400,
                  color:
                    deliverable.status !== DeliverableStatus.CLEAN
                      ? "#7A7A79"
                      : "#242D35",
                  lineHeight: "1.192rem",
                  padding: "0.75rem 1rem",
                  height: "2.5rem",
                }}
              >
                <TextWithTooltip
                  tooltipProps={{arrow: true, placement: "bottom-start"}}
                  tooltipStyle={{
                    "& .MuiTooltip-arrow": {
                      // move arrow to the left
                      //left: "10px !important",
                      transform: "none !important",
                    },
                  }}
                  text={deliverable.createdbyUser}
                  textStyle={{
                    fontSize: "0.875rem",
                    fontWeight: 400,
                    color:
                      deliverable.status !== DeliverableStatus.CLEAN
                        ? "#7A7A79"
                        : "#242D35",
                    lineHeight: "1.192rem",
                  }}
                  limit={14}
                />
              </TableCell>
              <TableCell
                sx={{
                  width: "20%",
                  fontSize: "0.875rem",
                  fontWeight: 400,
                  color:
                    deliverable.status !== DeliverableStatus.CLEAN
                      ? "#7A7A79"
                      : "#242D35",
                  lineHeight: "1.192rem",
                  padding: "0.75rem 1rem",
                  height: "2.5rem",
                  position: "relative",
                }}
              >
                {moment(deliverable.createdTimestamp).format(dateFormat) || ""}
                {deliverable.status !== DeliverableStatus.IN_PROGRESS ? (
                  <Stack
                    id={`deliverable-${deliverable.id}-actions`}
                    className="deliverable-actions"
                    direction="row"
                    position="absolute"
                    width="85%"
                    height="100%"
                    top="0"
                    right="0"
                    alignItems="center"
                    justifyContent="flex-end"
                    gap="0.5rem"
                    paddingRight="1rem"
                    sx={{
                      opacity: 0,
                      transition: "opacity 0.2s ease-in-out",
                      background:
                        "linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #EEEEEE 15%)",
                    }}
                  >
                    <Tooltip
                      arrow
                      title={
                        deliverable.status === DeliverableStatus.QUARANTINE
                          ? "You cannot download a quarantined file"
                          : null
                      }
                    >
                      <Stack>
                        <IconButton
                          disableRipple
                          disabled={
                            deliverable.status !== DeliverableStatus.CLEAN ||
                            downloadFileMutation.isPending
                          }
                          aria-label="download deliverable"
                          title="Download deliverable"
                          onClick={(e) => {
                            e.stopPropagation()
                            handleDownload(deliverable)
                          }}
                          sx={{
                            padding: 0,
                            borderRadius: "4px",
                            backgroundColor: "transparent",
                            transition: "background-color 0.2s ease-in-out",
                            ":hover": {
                              backgroundColor: "#E6EBED",
                            },
                            ":active": {
                              backgroundColor: "#CCD8DC",
                            },
                          }}
                        >
                          <DownloadIcon height="24" width="23.4" />
                        </IconButton>
                      </Stack>
                    </Tooltip>
                    {decoded?.companyId !== "0011" ? (
                      <IconButton
                        disableRipple
                        aria-label="delete deliverable"
                        title="Delete deliverable"
                        onClick={(e) => {
                          e.stopPropagation()
                          setDeliverableToDelete(deliverable.id)
                        }}
                        sx={{
                          padding: 0,
                          borderRadius: "4px",
                          backgroundColor: "transparent",
                          transition: "background-color 0.2s ease-in-out",
                          ":hover": {
                            backgroundColor: "#E6EBED",
                          },
                          ":active": {
                            backgroundColor: "#CCD8DC",
                          },
                        }}
                      >
                        <BinIcon height="24" width="24" />
                      </IconButton>
                    ) : null}
                  </Stack>
                ) : null}
              </TableCell>
            </TableRow>
          ),
        )}
      </Table>
    </Fragment>
  )
}

interface FoldersGridProps {
  foldersQuery: UseQueryResult<PagedResult<FolderModel>>
  deliverablesListQuery: any // using the real type causes an error, setting as "any" for now UseQueryResult<PagedResult<DeliverableModel>>
  filters: {searchText: string}
}

const FoldersGrid: FC<FoldersGridProps> = ({
  foldersQuery,
  deliverablesListQuery,
  filters,
}: FoldersGridProps) => {
  const folders =
    foldersQuery?.data?.results.filter(
      (f) =>
        f.folderName.toLowerCase() !== "client uploads" &&
        f.folderName.toLowerCase() !== "clientuploads",
    ) || []

  const filteredDeliverablesList =
    deliverablesListQuery?.data?.results?.filter((x: DeliverableModel) =>
      x.deliverableName
        .toLowerCase()
        .trim()
        .includes(filters.searchText.toLowerCase().trim()),
    ) || []

  return (
    <Stack direction="column" maxHeight="calc(100% - 121px)" overflow="auto">
      <FoldersGridView folders={folders} />
      {filters.searchText.length > 0 && (
        <DeliverablesGridView deliverables={filteredDeliverablesList} />
      )}
    </Stack>
  )
}

interface FoldersListProps {
  foldersQuery: UseQueryResult<PagedResult<FolderModel>>
  deliverablesListQuery: any // using the real type causes an error, setting as "any" for now UseQueryResult<PagedResult<DeliverableModel>>
  filters: {searchText: string}
  sort: SortOptions | undefined
  setSort: any
}

const FoldersList: FC<FoldersListProps> = ({
  foldersQuery,
  deliverablesListQuery,
  filters,
  sort,
  setSort,
}: FoldersListProps) => {
  const folders =
    foldersQuery?.data?.results.filter(
      (f) =>
        f.folderName.toLowerCase() !== "client uploads" &&
        f.folderName.toLowerCase() !== "clientuploads",
    ) || []
  const filteredDeliverablesList =
    deliverablesListQuery?.data?.results?.filter((x: DeliverableModel) =>
      x.deliverableName
        .toLowerCase()
        .trim()
        .includes(filters.searchText.toLowerCase().trim()),
    ) || []

  return (
    <Stack direction="column" minHeight="100%" overflow="auto" width="100%">
      <FoldersListView
        deliverables={filteredDeliverablesList}
        folders={folders}
        searchText={filters.searchText}
        sortState={{
          sortBy: sort || "",
          setSortBy: setSort,
        }}
      />
    </Stack>
  )
}

interface AccordionExtraActionProps {
  viewMode: "list" | "grid"
  sort?: SortOptions
  handleToggleSort?: () => void
  action?: string
  actionHandler?: any
  handleOpenChecklistDrawer?: () => void
}

const AccordionExtraAction: FC<AccordionExtraActionProps> = ({
  viewMode,
  sort,
  handleToggleSort,
  action,
  actionHandler,
  handleOpenChecklistDrawer,
}: AccordionExtraActionProps) => {
  const {t} = useTranslation()
  if (action === "sort") {
    if (viewMode === "grid") {
      return (
        <Stack
          direction="row"
          gap="0.375rem"
          alignItems="center"
          onClick={handleToggleSort}
          sx={{
            cursor: "pointer",
            svg: {
              height: "1.25rem",
              width: "1.25rem",
              fill: "#053747",
              color: "#053747",
            },
          }}
        >
          <Typography
            fontSize="1rem"
            fontWeight="600"
            lineHeight="1.5rem"
            color="#242D35"
          >
            {t("Sort")}
          </Typography>
          <Box
            sx={{
              position: "relative",
              height: "1.25rem",
              width: "1.25rem",

              ".hidden": {
                opacity: 0,
              },
              div: {
                opacity: 1,
                transition: "opacity 0.2s ease-in-out",
              },
              svg: {
                position: "absolute",
                top: "0",
                left: "0",
              },
            }}
          >
            <ReactSVG
              src="/icons/sort-az.svg"
              className={sort === "name" ? "" : "hidden"}
            />
            <ReactSVG
              src="/icons/sort-za.svg"
              className={sort === "-name" ? "" : "hidden"}
            />
          </Box>
        </Stack>
      )
    }
  } else if (action === "upload") {
    const openDrawer = (event: any) => {
      event?.stopPropagation()
      actionHandler(true)
    }
    return (
      <Stack spacing={1} direction="row">
        {handleOpenChecklistDrawer ? (
          <NewButton
            onClick={(e) => {
              e.stopPropagation()
              handleOpenChecklistDrawer()
            }}
            variant="underline"
            text={t("See checklist")}
            size="medium"
            color="primary"
            disableRipple
            sx={{
              borderRadius: "0 !important",
            }}
          />
        ) : null}
        <Button
          sx={{
            minWidth: "145px",
            textTransform: "none",
            backgroundColor: "#053747",
          }}
          onClick={openDrawer}
        >
          <Stack direction="row" spacing={1}>
            <PlusIcon fill={"white"} />
            <Typography sx={{color: "white"}}>{t("Upload files")}</Typography>
          </Stack>
        </Button>
      </Stack>
    )
  }

  return null
}

interface ExpandableFolderHeaderProps {
  foldersQuery?: UseQueryResult<PagedResult<FolderModel>>
  deliverablesListQuery?: UseQueryResult<PagedResult<DeliverableModel>>
  yourUploadsFolderQuery?: UseQueryResult<PagedResult<DeliverableModel>>
  showDeliverablesAsTable?: boolean
  count?: number
  tableSortBy?: string
  setTableSortBy?: any
  expandedAccordion?: string
  setExpandedAccordion?: any
  title?: string
  viewMode: "list" | "grid"
  sort?: SortOptions
  setSort?: any
  handleToggleSort?: any
  extraAction?: string
  actionHandler?: any
  handleOpenChecklistDrawer?: () => void
  filters: {searchText: string}
  icon?: ReactElement
  project?: ProjectAssignmentModel
  client?: ClientModel
}

const ExpandableFolderHeader: FC<ExpandableFolderHeaderProps> = ({
  foldersQuery,
  deliverablesListQuery,
  yourUploadsFolderQuery,
  showDeliverablesAsTable,
  count,
  tableSortBy,
  setTableSortBy,
  expandedAccordion,
  setExpandedAccordion,
  title,
  viewMode,
  sort,
  setSort,
  handleToggleSort,
  extraAction,
  actionHandler,
  handleOpenChecklistDrawer,
  filters,
  icon,
  project,
  client,
}) => {
  const {t} = useTranslation()
  const handleReloadData = () => {
    foldersQuery?.refetch()
    deliverablesListQuery?.refetch()
    yourUploadsFolderQuery?.refetch()
  }

  return foldersQuery ? (
    <Accordion
      sx={{
        width: "100%",
        outlineWidth: 0,
        backgroundColor: "#FBFBFB",
        outlineColor: "transparent",
        boxShadow: "none",
      }}
      disableGutters
      expanded={expandedAccordion === title}
    >
      <AccordionSummary
        sx={{padding: 0, borderBottom: "1px solid #BDBDBC"}}
        onClick={() => {
          setExpandedAccordion(title)
        }}
      >
        <Stack direction="row" justifyContent={"space-between"} width="100%">
          <Stack direction="row" gap={3} alignItems={"center"}>
            {expandedAccordion === title ? (
              <ChevronDownIcon
                fill="#053747"
                height="0.75rem"
                width="0.75rem"
              />
            ) : (
              <ChevronUpIcon fill="#053747" height="0.75rem" width="0.75rem" />
            )}
            <Stack direction="row" gap={1} alignItems={"center"}>
              {icon}
              <Typography
                component="h1"
                fontSize="1rem"
                fontWeight="600"
                lineHeight="1.5rem"
                color="#242D35"
                margin="0"
              >
                {t(title || "")}
              </Typography>
              {foldersQuery.isLoading ? (
                <Skeleton variant="text" width="1.5rem" height="1.5rem" />
              ) : (
                <Typography
                  component="h1"
                  fontSize="1rem"
                  fontWeight="600"
                  lineHeight="1.5rem"
                  color="#242D35"
                  margin="0"
                >
                  ({count ? count : 0})
                </Typography>
              )}
            </Stack>
          </Stack>
          <AccordionExtraAction
            viewMode={viewMode}
            sort={sort}
            handleToggleSort={handleToggleSort}
            action={extraAction}
            actionHandler={actionHandler}
            handleOpenChecklistDrawer={handleOpenChecklistDrawer}
          />
        </Stack>
      </AccordionSummary>
      <AccordionDetails>
        {showDeliverablesAsTable && !deliverablesListQuery?.isLoading ? (
          (!yourUploadsFolderQuery?.isFetching ||
            !yourUploadsFolderQuery?.isLoading) &&
          yourUploadsFolderQuery?.isFetchedAfterMount ? (
            <DeliverablesTableDisplay
              yourUploadsList={yourUploadsFolderQuery?.data?.results.sort(
                (a, b) => {
                  return (
                    ["quarantine", "in_progress", "clean"].indexOf(
                      a.status || "",
                    ) -
                    ["quarantine", "in_progress", "clean"].indexOf(
                      b.status || "",
                    )
                  )
                },
              )}
              tableSortBy={tableSortBy || ""}
              setTableSortBy={setTableSortBy}
              handleReloadData={handleReloadData}
              project={project}
              client={client}
            />
          ) : (
            <Loader viewMode={viewMode} />
          )
        ) : (
          <>
            {foldersQuery?.isLoading ||
            foldersQuery.isFetching ||
            deliverablesListQuery?.isLoading ||
            deliverablesListQuery?.isFetching ? (
              <Loader viewMode={viewMode} />
            ) : foldersQuery?.data?.results.length === 0 &&
              !filters.searchText ? (
              <NoData />
            ) : foldersQuery.data?.results.length == 0 &&
              deliverablesListQuery?.data?.results.length === 0 &&
              viewMode === "grid" ? (
              <NoMatchesFound />
            ) : viewMode === "grid" ? (
              <FoldersGrid
                foldersQuery={foldersQuery}
                deliverablesListQuery={deliverablesListQuery}
                filters={filters}
              />
            ) : viewMode === "list" ? (
              <FoldersList
                foldersQuery={foldersQuery}
                deliverablesListQuery={deliverablesListQuery}
                filters={filters}
                sort={sort}
                setSort={setSort}
              />
            ) : null}
          </>
        )}
      </AccordionDetails>
    </Accordion>
  ) : null
}

interface SearchResultsProps {
  foldersQuery: UseQueryResult<PagedResult<FolderModel>>
  deliverablesListQuery: any // using the real type causes an error, setting as "any" for now UseQueryResult<PagedResult<DeliverableModel>>
  filters: {searchText: string}
  viewMode: "list" | "grid"
  sort?: SortOptions
  setSort?: any
}

const SearchResults: FC<SearchResultsProps> = ({
  foldersQuery,
  deliverablesListQuery,
  filters,
  viewMode,
  sort,
  setSort,
}: SearchResultsProps) => {
  return (
    <Stack sx={{height: "100%"}}>
      {foldersQuery?.isLoading ||
      foldersQuery.isFetching ||
      deliverablesListQuery?.isLoading ||
      deliverablesListQuery?.isFetching ? (
        <Loader viewMode={""} />
      ) : foldersQuery?.data?.results.length === 0 && !filters.searchText ? (
        <NoData />
      ) : foldersQuery.data?.results.length == 0 &&
        deliverablesListQuery?.data?.results.length === 0 &&
        viewMode === "grid" ? (
        <NoMatchesFound />
      ) : viewMode === "grid" ? (
        <FoldersGrid
          foldersQuery={foldersQuery}
          deliverablesListQuery={deliverablesListQuery}
          filters={filters}
        />
      ) : viewMode === "list" ? (
        <FoldersList
          foldersQuery={foldersQuery}
          deliverablesListQuery={deliverablesListQuery}
          filters={filters}
          sort={sort}
          setSort={setSort}
        />
      ) : null}
    </Stack>
  )
}

const ViewProject: FC = () => {
  const [viewMode, setViewMode] = useState<"list" | "grid">("grid")
  const [filters, setFilters] = useState<{searchText: string}>({searchText: ""})
  const [sort, setSort] = useState<SortOptions>("name")
  const [tableSortBy, setTableSortBy] = useState<string>("-publishedDate")
  const [openDrawer, setOpenDrawer] = useState<boolean>(false)
  const [openChecklistDrawer, setOpenChecklistDrawer] = useState<boolean>(false)
  const [openModeMenu, setOpenModeMenu] = useState<boolean>(false)
  const modeAnchorRef = useRef<HTMLButtonElement>(null)
  const queryClient = new QueryClient()
  const {basename} = useConfigProvider()
  const {t} = useTranslation()

  //const theme = useTheme()
  const {id} = useParams<{id: string}>()
  const {getUserProjectById, getFoldersByProjectId, getClientsByProjectId} =
    projectsEndpoints()

  const {
    getDeliverablesByProjectId,
    getDeliverablesByFolderId,
    getDeliverableFileStatus,
  } = deliverableEndpoints()

  const [expandedAccordion, setExpandedAccordion] =
    useState<string>("Your folders")

  const projectQuery = useQuery<ProjectAssignmentModel>({
    queryKey: ["projectByUserId", id],
    queryFn: () => getUserProjectById(id || ""),
  })

  const foldersQuery = useQuery<PagedResult<FolderModel>>({
    queryKey: [
      "getFolders",
      id,
      filters.searchText,
      viewMode,
      sort,
      projectQuery.data,
    ],
    queryFn: () => {
      const queryString = `query=${
        filters.searchText ? encodeURIComponent(filters.searchText) : ""
      }&limit=${limit}&sortBy=${sort}`
      return getFoldersByProjectId(id || "", queryString)
    },
    refetchOnWindowFocus: false,
  })
  const deliverablesListQuery = useQuery<PagedResult<DeliverableModel>>({
    queryKey: [
      "getDeliverablesByProjectId",
      projectQuery.data?.id || "",
      filters.searchText,
      sort,
    ],
    enabled: !!projectQuery.data?.id,
    queryFn: () => {
      const limit = 9999
      const queryString = `query=${
        filters.searchText ? encodeURIComponent(filters.searchText) : ""
      }&limit=${limit}&sortBy=${sort}`
      return getDeliverablesByProjectId(
        projectQuery.data?.id || "",
        queryString,
        false, // showExpired
      )
    },
  })
  const [yourUploadsFolderId, setYourUploadsFolderId] = useState<string>("")

  useEffect(() => {
    if (foldersQuery?.data) {
      const id =
        foldersQuery?.data?.results?.find(
          (x) => x.folderName.toLowerCase() === "client uploads",
        )?.folderId || ""
      if (id !== "") {
        setYourUploadsFolderId(id)
      }
    }
  }, [foldersQuery?.data])

  const yourUploadsFolderQuery = useQuery<PagedResult<DeliverableModel>>({
    queryKey: [
      "getYourUploadsDeliverables",
      foldersQuery?.data?.results?.find(
        (x) => x.folderName.toLowerCase() === "client uploads",
      )?.folderId,
      tableSortBy,
      // openDrawer,
      // yourUploadsFolderId,
    ],
    queryFn: () => {
      const query = `query=&sortBy=${tableSortBy}&limit=${999}&isClientUploads=true`
      return getDeliverablesByFolderId(yourUploadsFolderId, query)
    },
    enabled: yourUploadsFolderId !== "",
  })

  const pendingDeliverables = useMemo(() => {
    return (
      yourUploadsFolderQuery.data?.results.filter(
        (deliverable) =>
          deliverable.status === "in_progress" &&
          deliverable.deliverableType === "report",
      ) || []
    )
  }, [
    yourUploadsFolderQuery.data?.results,
    yourUploadsFolderQuery.isLoading,
    yourUploadsFolderQuery.isFetchedAfterMount,
  ])

  useQueries<DeliverableStatusQuery[]>({
    queries: pendingDeliverables.map((deliverable) => ({
      queryFn: () => getDeliverableFileStatus(deliverable.id),
      queryKey: [
        "fileStatus",
        deliverable.id,
        id,
        yourUploadsFolderId,
        deliverable.status,
      ],
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchInterval: (query: any) => {
        queryClient.refetchQueries({queryKey: ["projectByUserId"], exact: true})
        if (query.state.data?.scanResult === "in_progress") return 5000
        return false
      },
    })),
    // @ts-ignore
    combine: (response) => {
      const data =
        (response?.map((res) => res.data) as DeliverableStatusQuery[]) ||
        ([] as DeliverableStatusQuery[])
      const isLoading = response.some((res) => res.isLoading)
      const isFetching = response.some((res) => res.isFetching)
      const isSuccess = !response.find((res) => res.isSuccess === false)
      const isError = response.some((res) => res.isError)
      const isFetched = response.some((res) => res.isFetched)

      const cleanDeliverables =
        data?.filter((res) => res?.scanResult !== "in_progress") || []

      if (
        !isLoading &&
        !isFetching &&
        data?.length &&
        cleanDeliverables?.length
      ) {
        queryClient.invalidateQueries({
          queryKey: ["getYourUploadsDeliverables"],
          exact: true,
        })
        yourUploadsFolderQuery.refetch()
      }
      return {
        data: data,
        isLoading: isLoading,
        isFetching: isFetching,
        isSuccess: isSuccess,
        isFetched: isFetched,
        isError: isError,
      }
    },
  })

  const clientQuery = useQuery<PagedResult<ClientModel>>({
    enabled:
      projectQuery.isFetched &&
      !projectQuery.isFetching &&
      !projectQuery.isLoading,
    queryKey: ["getClient", projectQuery.data?.id || ""],
    queryFn: () => {
      return getClientsByProjectId(id || "")
    },
    refetchOnWindowFocus: false,
  })

  const handleViewModeChange = (mode: "list" | "grid") => {
    setViewMode(mode)
    foldersQuery.refetch()
  }

  const handleToggleModeMenu = () => {
    setOpenModeMenu((s) => !s)
  }

  const handleToggleSort = (event: any) => {
    setSort((s) => (s === "name" ? "-name" : "name"))
    event.stopPropagation()
  }

  const handleSearchChange = (searchText: string) => {
    setFilters((prev) => ({
      ...prev,
      searchText,
    }))
  }

  const closeDrawer = () => {
    setOpenDrawer(false)
  }

  const handleToggleChecklistDrawer = () => {
    setOpenChecklistDrawer((s) => !s)
  }

  const setCurrentPage = usePageStore((state) => state.setCurrentPage)
  useEffect(() => {
    setCurrentPage(
      projectQuery.data?.projectAssignmentDisplayName ||
        projectQuery.data?.projectAssignmentName ||
        "General",
    )
  }, [
    projectQuery.data?.projectAssignmentDisplayName,
    projectQuery.data?.projectAssignmentName,
  ])

  if (
    id &&
    guidRegex.test(id) === false &&
    !projectQuery.isLoading &&
    !projectQuery.isFetching &&
    !projectQuery.isPending
  ) {
    return <ErrorScreen /> // FOR TESTING, WILL REMOVE LATER
  }

  const disabled =
    foldersQuery.isLoading ||
    foldersQuery.isFetching ||
    !foldersQuery.data ||
    (foldersQuery.data.results?.length === 0 && !filters.searchText)

  if (
    (!projectQuery.data?.enabled &&
      !projectQuery.isLoading &&
      !projectQuery.isFetching &&
      !projectQuery.isPending) ||
    (!clientQuery.data &&
      !clientQuery.isLoading &&
      !clientQuery.isFetching &&
      !clientQuery.isPending)
  ) {
    return (
      <AccessDenied
        resource="project"
        resourceName={projectQuery?.data?.projectAssignmentDisplayName}
      />
    )
  }

  return (
    <Fragment>
      <Stack direction="row" flexGrow={1} component="section" height="100%">
        <UploadFileDrawer
          openDrawer={openDrawer}
          onClose={closeDrawer}
          client={clientQuery?.data}
          project={projectQuery}
        />
        <LeftPanelLayout>
          <ProjectSummary
            projectQuery={projectQuery}
            yourUploadsFolderQuery={yourUploadsFolderQuery}
          />
        </LeftPanelLayout>
        <Stack
          paddingX={{xs: "1rem", md: "2rem", xl: "6rem"}}
          paddingY="1.5rem"
          gap="1.25rem"
          flexGrow={1}
          direction="column"
          component="main"
          marginLeft="236px"
          height="100%"
        >
          <Stack direction="column" component="header" gap="0.5rem">
            <Stack
              direction="row"
              gap="1rem"
              alignItems="center"
              justifyContent="space-between"
            >
              <Breadcrumb
                links={[
                  {
                    label: t("Projects"),
                    href: `${basename}`,
                  },
                  {
                    label:
                      projectQuery.data?.projectAssignmentDisplayName ||
                      projectQuery.data?.projectAssignmentName ||
                      "",
                    href: `${basename}/${id}`,
                    active: true,
                    loading: projectQuery.isLoading,
                  },
                ]}
              />
              <Stack
                direction="row"
                gap="1.5rem"
                alignItems="center"
                flex={1}
                justifyContent="flex-end"
                maxWidth="466px"
              >
                <SearchBarr
                  size="small"
                  state="collapsed"
                  disabled={disabled}
                  onChange={handleSearchChange}
                  containerProps={{
                    sx: {
                      width: "15rem",
                    },
                  }}
                />
                <NewButton
                  icon
                  ref={modeAnchorRef}
                  variant="text"
                  color="primary"
                  onClick={handleToggleModeMenu}
                  disabled={disabled}
                  IconLeft={
                    <ReactSVG
                      src={`/icons/${
                        viewMode === "list" ? "list-view" : "grid-view"
                      }${viewMode === "list" ? "-selected" : ""}.svg`}
                      color="#595958"
                    />
                  }
                  IconRight={
                    <ArrowDropDownIcon
                      color="#595958"
                      height="0.75rem"
                      width="0.75rem"
                    />
                  }
                  sx={{
                    padding: "0.4375rem 0.5rem",
                    width: "auto",
                    minWidth: "auto",
                    color: "#595958",
                    "> svg": {
                      height: "0.75rem",
                      width: "0.75rem",
                    },
                    "div > div": {
                      height: "1.5rem",
                      width: "1.5rem",

                      svg: {
                        height: "1.5rem",
                        width: "1.5rem",
                      },
                    },
                  }}
                />
                <Popover
                  anchorEl={modeAnchorRef.current}
                  open={openModeMenu}
                  handleClose={handleToggleModeMenu}
                  items={[
                    {
                      text: t("View mode"),
                      section: true,
                      items: [
                        {
                          text: t("List view"),
                          Icon: <ReactSVG src="/icons/list-view.svg" />,
                          selected: viewMode === "list",
                          onClick: () => {
                            handleViewModeChange("list")
                            handleToggleModeMenu()
                          },
                        },
                        {
                          text: t("Grid view"),
                          Icon: <ReactSVG src="/icons/grid-view.svg" />,
                          selected: viewMode === "grid",
                          onClick: () => {
                            handleViewModeChange("grid")
                            handleToggleModeMenu()
                          },
                        },
                      ],
                    },
                  ]}
                />
              </Stack>
            </Stack>
            {foldersQuery?.data?.results?.length === 0 &&
            !filters.searchText ? null : (
              <Stack
                direction="row"
                gap="0.5rem"
                alignItems="center"
                justifyContent="space-between"
              >
                {filters.searchText.length > 0 ? (
                  <Stack direction="row" gap="0.5rem" alignItems="center">
                    <Typography
                      component="h1"
                      fontSize="1rem"
                      fontWeight="600"
                      lineHeight="1.5rem"
                      color="#242D35"
                      margin="0"
                    >
                      Search results
                    </Typography>
                    <Stack
                      height="1.5rem"
                      width="1.5rem"
                      alignContent="center"
                      justifyContent="center"
                      borderRadius="100%"
                      bgcolor="#BDBDBC"
                      textAlign="center"
                    >
                      <Typography
                        fontSize="0.875rem"
                        lineHeight="1.25rem"
                        fontWeight="500"
                        color="#242D35"
                        textAlign="center"
                      >
                        {!foldersQuery.isLoading &&
                        !deliverablesListQuery.isLoading &&
                        !deliverablesListQuery.isFetching ? (
                          (deliverablesListQuery?.data?.results?.length || 0) +
                          (foldersQuery?.data?.results?.filter(
                            (f) =>
                              f.folderName.toLowerCase() != "client uploads",
                          ).length || 0)
                        ) : (
                          <Skeleton
                            variant="text"
                            width="1.5rem"
                            height="1.5rem"
                          />
                        )}
                      </Typography>
                    </Stack>
                  </Stack>
                ) : (
                  <Stack sx={{width: "100%"}} bgcolor={"#FBFBFB"}>
                    <ExpandableFolderHeader
                      foldersQuery={foldersQuery}
                      expandedAccordion={expandedAccordion}
                      setExpandedAccordion={setExpandedAccordion}
                      count={
                        foldersQuery?.data?.results.filter(
                          (f) =>
                            f.folderName.toLowerCase() !== "client uploads" &&
                            f.folderName.toLowerCase() !== "clientuploads",
                        ).length
                      }
                      title={"Your folders"}
                      viewMode={viewMode}
                      sort={sort}
                      setSort={setSort}
                      handleToggleSort={handleToggleSort}
                      extraAction={"sort"}
                      deliverablesListQuery={deliverablesListQuery}
                      filters={filters}
                      icon={
                        <FolderIcon fill="#053747" height="1rem" width="1rem" />
                      }
                      project={projectQuery.data}
                      client={clientQuery.data?.results[0]}
                    />
                    {projectQuery?.isLoading ||
                    projectQuery?.isFetching ? null : projectQuery?.data
                        ?.subscriptionProjectAssignment ? null : (
                      <ExpandableFolderHeader
                        foldersQuery={foldersQuery}
                        deliverablesListQuery={deliverablesListQuery}
                        yourUploadsFolderQuery={yourUploadsFolderQuery}
                        showDeliverablesAsTable={true}
                        expandedAccordion={expandedAccordion}
                        setExpandedAccordion={setExpandedAccordion}
                        count={yourUploadsFolderQuery?.data?.results.length}
                        title={"Your uploads"}
                        viewMode={"list"}
                        tableSortBy={tableSortBy}
                        setTableSortBy={setTableSortBy}
                        extraAction={"upload"}
                        actionHandler={setOpenDrawer}
                        handleOpenChecklistDrawer={
                          projectQuery?.data?.projectCheckList
                            ? handleToggleChecklistDrawer
                            : undefined
                        }
                        filters={filters}
                        icon={
                          <UploadIcon
                            fill="#053747"
                            height="1rem"
                            width="1rem"
                          />
                        }
                        project={projectQuery.data}
                        client={clientQuery.data?.results[0]}
                      />
                    )}
                  </Stack>
                )}
              </Stack>
            )}
          </Stack>
          {filters.searchText.length > 0 ? (
            <>
              <Box
                component="hr"
                sx={{
                  border: 0,
                  borderTopWidth: "1px",
                  borderTopStyle: "solid",
                  borderTopColor: "#BDBDBC",
                }}
              />
              <SearchResults
                foldersQuery={foldersQuery}
                deliverablesListQuery={deliverablesListQuery}
                filters={filters}
                viewMode={viewMode}
              />
            </>
          ) : null}
        </Stack>
      </Stack>
      <ChecklistDrawer
        open={openChecklistDrawer}
        handleClose={handleToggleChecklistDrawer}
        project={projectQuery.data}
      />
    </Fragment>
  )
}

export default ViewProject
