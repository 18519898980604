import {useConfigProvider} from "../../../config/ConfigProvider"
import {
  AskAnExpertNotification,
  ClientUploadNotification,
  Notification,
  ProjectQuestionEmail,
} from "../../types/notification"

export function notificationEndpoints() {
  const {token, notificationFaToken, apimKeyNotification, notificationUrl} =
    useConfigProvider()
  const headers = {
    "Content-Type": "application/json",
    "X-Functions-Key": notificationFaToken || "",
    "Ocp-Apim-Subscription-Key": apimKeyNotification || "",
    Authorization: `Bearer ${token}`,
  }

  const sendClientSupportNotificationEmail = async (payload: Notification) => {
    const url = `${notificationUrl}/notification/client-support`
    return await fetch(url, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(payload),
    }).then((res) => res.json())
  }

  const sendAskAnExpertNotificationEmail = async (
    payload: AskAnExpertNotification,
  ) => {
    const url = `${notificationUrl}/notification/ask-an-expert`
    return await fetch(url, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(payload),
    }).then((res) => res.json())
  }

  const sendProjectQuestionEmail = async (payload: ProjectQuestionEmail) => {
    const url = `${notificationUrl}/notification/project-question`
    return await fetch(url, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(payload),
    }).then((res) => res.json())
  }

  const sendClientUploadNotification = async (
    payload: ClientUploadNotification,
  ) => {
    const url = `${notificationUrl}/notification/client-upload`
    return await fetch(url, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(payload),
    }).then((res) => res.json())
  }

  return {
    sendClientSupportNotificationEmail,
    sendAskAnExpertNotificationEmail,
    sendProjectQuestionEmail,
    sendClientUploadNotification,
  }
}
