import {useEffect, type FC} from "react"
import {RouterProvider} from "react-router-dom"
import {
  MutationCache,
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query"
import {createRouter} from "./router"
import {AppInsightsContext} from "@microsoft/applicationinsights-react-js"
import {reactPlugin} from "./config/appInsights"
import {Toast} from "./components"
import {toast} from "react-toastify"
import {ErrorIcon} from "./assets/icons"
import {AccountPanelState} from "./root.component"
import {StoreApi, UseBoundStore} from "zustand"
import {Token} from "./types/Token"
import {useConfigProvider} from "./config"
import {decodeToken} from "react-jwt"
import {useClientSessionStore} from "./stores/clientSessionStore"
import useStorage, {StorageData} from "./hooks/useStorage"
import {ReactQueryDevtools} from "@tanstack/react-query-devtools"
import {useTranslation} from "react-i18next"

type Props = {
  useAccountPanelStore: UseBoundStore<StoreApi<AccountPanelState>>
  basename: string
}

const App: FC<Props> = (props) => {
  const {useAccountPanelStore, basename} = props
  const {token} = useConfigProvider()
  const router = createRouter(basename)
  const {t} = useTranslation()

  const decoded = decodeToken<Token>(token)

  const storage = useStorage("session", StorageData.crmAccount)

  const setShowAccountPanel = useAccountPanelStore(
    (state) => state.setShowAccountPanel,
  )
  const setSessionClient = useClientSessionStore((state) => state.setClient)

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        throwOnError: false, // Set this to false to not throw errors in the UI when a query fails
      },
    },
    queryCache: new QueryCache({
      onError: (error) => {
        toast.error(
          t("An unexpected error occurred. Please try refresh your page."),
          {
            autoClose: 3000,
            icon: ErrorIcon,
            toastId: "api-error-toast",
          },
        )
      },
    }),
    mutationCache: new MutationCache({
      onError: (error) => {
        toast.error(
          t("An unexpected error occurred. Please try refresh your page."),
          {
            autoClose: 3000,
            icon: ErrorIcon,
            toastId: "api-error-toast",
          },
        )
      },
    }),
  })

  useEffect(() => {
    if (!storage.get()) {
      setShowAccountPanel(true)
    } else {
      setSessionClient(storage.get() || "")
      setShowAccountPanel(false)
    }
  }, [])

  useEffect(() => {
    if (decoded?.companyId) {
      setSessionClient(decoded.companyId)
    }
  }, [decoded?.companyId, setSessionClient])

  return (
    <AppInsightsContext.Provider value={reactPlugin}>
      <QueryClientProvider client={queryClient}>
        <RouterProvider router={router} />
        <ReactQueryDevtools initialIsOpen={false} />
        <Toast />
      </QueryClientProvider>
    </AppInsightsContext.Provider>
  )
}

export default App
