import {useConfigProvider} from "../../../config/ConfigProvider"

export function projectsEndpoints() {
  const {token, faToken, apimKey, serverUrl} = useConfigProvider()
  const headers = {
    "Content-Type": "application/json",
    "X-Functions-Key": faToken || "",
    "Ocp-Apim-Subscription-Key": apimKey || "",
    Authorization: `Bearer ${token}`,
  }

  const getProjectsByUserId = async (query: string) => {
    const request = `${serverUrl}/users/projects`
    return await fetch(`${request}?${query}`, {headers: headers}).then((res) =>
      res.json(),
    )
  }

  const getUserProjectById = async (id: string) => {
    const request = `${serverUrl}/users/projects/${id}`
    return await fetch(`${request}`, {headers: headers}).then((res) =>
      res.json(),
    )
  }

  const getProjectById = async (id: string, query: string) => {
    const request = `${serverUrl}/user/projects/${id}`
    return await fetch(`${request}?${query}`, {headers: headers}).then((res) =>
      res.json(),
    )
  }

  const getFoldersByProjectId = async (id: string, query: string) => {
    const request = `${serverUrl}/users/projects/${id}/folders`
    return await fetch(`${request}?${query}`, {headers: headers}).then((res) =>
      res.json(),
    )
  }

  const getFolderById = async (projectId: string, folderId: string) => {
    const request = `${serverUrl}/user/projects/${projectId}/folders/${folderId}`
    return await fetch(request, {headers: headers}).then((res) => res.json())
  }

  const getClientsByProjectId = async (id: string) => {
    const url = `${serverUrl}/projects/${id}/clients`
    return await fetch(url, {headers: headers}).then((res) => res.json())
  }

  const getProjectsByClientId = async (id: string, query: string) => {
    const request = `${serverUrl}/clients/${id}/projects?${query}`
    return await fetch(request, {headers: headers}).then((res) => res.json())
  }

  const getAvailableRegions = async () => {
    const url = `${serverUrl}/regions`
    return await fetch(url, {headers: headers}).then((res) => res.json())
  }

  const getProjectUserClients = async (
    projectId: string,
    isSubscription: boolean,
  ) => {
    const request = `${serverUrl}/graph/userClients/project/${projectId}`
    const query = `&isSubscription=${isSubscription}`
    return await fetch(`${request}?${query}`, {headers: headers}).then((res) =>
      res.json(),
    )
  }

  const getProjectManager = async (
    serverUrl: string | undefined,
    projectId: string,
  ) => {
    const url = `${serverUrl}/projects/${projectId}/manager`

    return await fetch(url, {headers: headers}).then((res) => res.json())
  }

  return {
    getUserProjectById,
    getProjectsByUserId,
    getProjectsByClientId,
    getProjectById,
    getFoldersByProjectId,
    getFolderById,
    getClientsByProjectId,
    getProjectUserClients,
    getAvailableRegions,
    getProjectManager,
  }
}
