import {type FC, useState, useEffect, useMemo, useRef} from "react"
import {useNavigate, useParams} from "react-router-dom"
import {
  useQuery,
  useInfiniteQuery,
  keepPreviousData,
} from "@tanstack/react-query"
import {
  Skeleton,
  TableCell,
  TableRow,
  Typography,
  Stack,
  useTheme,
  Box,
} from "@mui/material"
import {ReactSVG} from "react-svg"
import moment from "moment"
import type {
  ClientModel,
  DeliverableModel,
  FolderModel,
  PagedResult,
  ProjectAssignmentModel,
} from "../../types"
import {projectsEndpoints} from "../../services/api/projects"
import {deliverableEndpoints} from "../../services/api/deliverables"
import {dateFormat, getDeliverableExtension, iconMap} from "../../helpers"
import {LeftPanelLayout} from "../../components/organisms/LeftPanelLayout/LeftPanelLayout"
import ProjectSummary from "../../components/organisms/LeftPanelLayout/Summary/ProjectSummary"
import {
  Breadcrumb,
  Table,
  TextWithTooltip,
  SearchBarr,
  TableHeadingProps,
  Tooltip,
} from "../../components"
import AccessDenied from "../AccessDenied/AccessDenied"
import usePageStore from "../../stores/pageStore"
import {useInView} from "react-intersection-observer"
import {useConfigProvider} from "../../config"
import {useTranslation} from "react-i18next"

const headings = [
  {name: "Name", field: "name", isSortable: true, sx: {width: "30%"}},
  {
    name: "Description",
    field: "description",
    isSortable: true,
    wide: true,
    sx: {width: "50%"},
  },
  {name: "Modified", field: "modified", isSortable: true, sx: {width: "10%"}},
  {
    name: "Created",
    field: "publishedDate",
    isSortable: true,
    sx: {width: "10%"},
  },
] satisfies TableHeadingProps[]

const limit = 17
const ViewFolder: FC = () => {
  const theme = useTheme()
  const {basename} = useConfigProvider()
  const descriptionColumnRef = useRef<HTMLTableCellElement>(null)
  const [descriptionCharacterLimit, setDescriptionCharacterLimit] = useState(75)
  const {id, folderId} = useParams<{id: string; folderId: string}>()
  const [filters, setFilters] = useState<{searchText: string}>({searchText: ""})
  const [sortBy, setSortBy] = useState<"publishedDate" | "-publishedDate">(
    "-publishedDate",
  )
  const [isLoadingSearch, setIsLoadingSearch] = useState(false)
  const {ref, inView} = useInView()
  const {t} = useTranslation()
  const {getUserProjectById, getFolderById, getClientsByProjectId} =
    projectsEndpoints()

  const {getDeliverablesByFolderId} = deliverableEndpoints()
  const navigate = useNavigate()

  const projectQuery = useQuery<ProjectAssignmentModel>({
    queryKey: ["projectByUserId", id],
    queryFn: () => getUserProjectById(id || ""),
  })

  const folderQuery = useQuery<FolderModel>({
    queryKey: ["getFolder", id, folderId],
    queryFn: () => {
      return getFolderById(id || "", folderId || "")
    },
  })

  const queryString = useMemo(() => {
    return `query=${
      filters.searchText ? encodeURIComponent(filters.searchText) : ""
    }&sortBy=${sortBy}&limit=${limit}`
  }, [filters.searchText, sortBy])

  const lazyDeliverablesQuery = useInfiniteQuery<PagedResult<DeliverableModel>>(
    {
      initialPageParam: 1,
      queryKey: [
        "getDeliverablesByFolderId",
        folderId,
        filters.searchText,
        sortBy,
      ],
      queryFn: ({pageParam}) => {
        const page = parseInt(pageParam as string) || 1
        const offset = (page - 1) * limit
        const paginatedQuery = `${queryString}&offset=${offset}`
        return getDeliverablesByFolderId(folderId || "", paginatedQuery)
      },
      getNextPageParam: (lastPage) => {
        if ((lastPage?.currentPage || 1) * limit < (lastPage?.rowCount || 0)) {
          return lastPage.currentPage + 1
        }
        return undefined
      },
      placeholderData: keepPreviousData,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    },
  )

  useEffect(() => {
    if (
      filters.searchText &&
      (lazyDeliverablesQuery.isLoading ||
        lazyDeliverablesQuery.isFetching ||
        lazyDeliverablesQuery.isRefetching)
    ) {
      setIsLoadingSearch(true)
    } else {
      setIsLoadingSearch(false)
    }
  }, [
    filters.searchText,
    lazyDeliverablesQuery.isLoading,
    lazyDeliverablesQuery.isFetching,
    lazyDeliverablesQuery.isRefetching,
  ])

  useEffect(() => {
    if (inView && lazyDeliverablesQuery.hasNextPage) {
      lazyDeliverablesQuery.fetchNextPage()
    }
  }, [
    inView,
    lazyDeliverablesQuery.hasNextPage,
    lazyDeliverablesQuery.fetchNextPage,
    ref,
  ])

  useEffect(() => {
    const handleDescriptionColumnWidth = () => {
      if (descriptionColumnRef.current) {
        const descriptionColumnWidth = descriptionColumnRef.current.clientWidth
        const characterWidth = 8.7
        setDescriptionCharacterLimit(
          Math.floor(descriptionColumnWidth / characterWidth),
        )
      }
    }
    handleDescriptionColumnWidth()
    window.addEventListener("resize", handleDescriptionColumnWidth)
    return () => {
      window.removeEventListener("resize", handleDescriptionColumnWidth)
    }
  }, [descriptionColumnRef.current])

  const filteredData = useMemo(() => {
    const reducedDeliverables = lazyDeliverablesQuery?.data?.pages?.reduce(
      (acc, {results}) => [...acc, ...results],
      [] as DeliverableModel[],
    )
    return reducedDeliverables
  }, [lazyDeliverablesQuery.data])

  const clientQuery = useQuery<PagedResult<ClientModel>>({
    enabled:
      projectQuery.isFetched &&
      !projectQuery.isFetching &&
      !projectQuery.isLoading,
    queryKey: ["getClient", projectQuery.data?.id || ""],
    queryFn: () => {
      return getClientsByProjectId(id || "")
    },
    refetchOnWindowFocus: false,
  })

  const handleNavigate = (deliverable: DeliverableModel) => {
    if (deliverable.deliverableType === "dashboard") {
      navigate(
        `${basename}/${id}/folders/${folderId}/dashboard/${deliverable.id}`,
      )
    } else if (deliverable.deliverableType === "web") {
      navigate(
        `${basename}/${id}/folders/${folderId}/web-report/${deliverable.id}`,
      )
    } else {
      navigate(`${basename}/${id}/folders/${folderId}/file/${deliverable.id}`)
    }
  }

  const handleSearchChange = (searchText: string) => {
    setFilters((prev) => ({
      ...prev,
      searchText,
    }))
  }

  const setCurrentPage = usePageStore((state) => state.setCurrentPage)
  useEffect(() => {
    setCurrentPage(
      projectQuery.data?.projectAssignmentDisplayName ||
        projectQuery.data?.projectAssignmentName ||
        "General",
    )
  }, [
    projectQuery.data?.projectAssignmentDisplayName,
    projectQuery.data?.projectAssignmentName,
  ])

  if (
    (!folderQuery.data?.isEnabled &&
      !folderQuery.isLoading &&
      !folderQuery.isFetching &&
      !folderQuery.isPending) ||
    (!projectQuery?.data?.enabled &&
      !projectQuery.isLoading &&
      !projectQuery.isFetching &&
      !projectQuery.isPending)
  ) {
    return (
      <AccessDenied
        resource="folder"
        resourceName={folderQuery?.data?.folderName}
      />
    )
  }

  if (
    (!folderQuery.data &&
      !folderQuery.isLoading &&
      !folderQuery.isFetching &&
      !folderQuery.isPending) ||
    (!projectQuery.data &&
      !projectQuery.isLoading &&
      !projectQuery.isFetching &&
      !projectQuery.isPending) ||
    (!clientQuery.data &&
      !clientQuery.isLoading &&
      !clientQuery.isFetching &&
      !clientQuery.isPending)
  ) {
    return (
      <AccessDenied
        resource="folder"
        resourceName={folderQuery?.data?.folderName}
      />
    )
  }

  return (
    <Stack flexGrow={1} flexDirection="row" component="section" height="100%">
      <LeftPanelLayout>
        <ProjectSummary projectQuery={projectQuery} />
      </LeftPanelLayout>
      <Stack
        paddingX={{xs: "1rem", md: "2rem", xl: "6rem"}}
        paddingY="1.5rem"
        gap="0.5rem"
        flexGrow={1}
        direction="column"
        component="main"
        marginLeft="236px"
      >
        <Stack
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          gap="1rem"
        >
          <Breadcrumb
            links={[
              {
                label: t("Projects"),
                href: `${basename}`,
              },
              {
                label:
                  projectQuery?.data?.projectAssignmentDisplayName ||
                  projectQuery?.data?.projectAssignmentName ||
                  "",
                href: `${basename}/${projectQuery?.data?.id}`,
                loading: projectQuery?.isLoading,
              },
              {
                label: folderQuery?.data?.folderName || "",
                href: `${basename}/${projectQuery?.data?.id}/folders/${folderQuery?.data?.folderId}`,
                loading: folderQuery?.isLoading,
                active: true,
              },
            ]}
          />
          <Stack
            flexGrow={1}
            direction="row"
            maxWidth="466px"
            justifyContent="flex-end"
          >
            <SearchBarr
              size="small"
              state="collapsed"
              disabled={
                !lazyDeliverablesQuery?.isLoading &&
                (!filteredData ||
                  (filteredData?.length === 0 &&
                    filters.searchText.length === 0))
              }
              onChange={handleSearchChange}
              containerProps={{
                sx: {
                  width: "18.75rem",
                },
              }}
            />
          </Stack>
        </Stack>
        <Stack>
          {filters.searchText.length > 0 ? (
            <Stack direction="row" gap="0.5rem" alignItems="center">
              <Typography
                component="h1"
                fontSize="1rem"
                fontWeight="600"
                lineHeight="1.5rem"
                color="#242D35"
                margin="0"
              >
                {t("Search results")}
              </Typography>
              <Stack
                height="1.5rem"
                width="1.5rem"
                alignContent="center"
                justifyContent="center"
                borderRadius="100%"
                bgcolor="#BDBDBC"
                textAlign="center"
              >
                <Typography
                  fontSize="0.875rem"
                  lineHeight="1.25rem"
                  fontWeight="500"
                  color="#242D35"
                  textAlign="center"
                >
                  {!lazyDeliverablesQuery?.isLoading && !isLoadingSearch ? (
                    lazyDeliverablesQuery?.data?.pages?.at(0)?.results.length ||
                    0
                  ) : (
                    <Skeleton variant="rectangular" width="100%" height={15} />
                  )}
                </Typography>
              </Stack>
            </Stack>
          ) : (
            <Typography
              component="h1"
              fontSize="1rem"
              fontWeight="600"
              lineHeight="1.5rem"
              color="#242D35"
              margin="0"
            >
              {t("Your deliverables")}
            </Typography>
          )}
        </Stack>
        <Box
          component="hr"
          sx={{
            border: 0,
            borderTopWidth: "1px",
            borderTopStyle: "solid",
            borderTopColor: "#BDBDBC",
          }}
        />
        <Stack height="calc(100% - 121px)">
          {!lazyDeliverablesQuery?.isLoading &&
          (!lazyDeliverablesQuery?.data || filteredData?.length === 0) &&
          filters.searchText.length === 0 ? (
            <Stack p={3} alignItems="center">
              <Typography color={theme.palette.info.dark}>
                {t("You have no deliverables allocated to you.")}
              </Typography>
            </Stack>
          ) : (
            <Table
              headings={headings}
              sortState={{sortBy, setSortBy}}
              filterState={{filters, setFilters}}
              style={{
                paddingTop: 0,
                height: "100%",
              }}
              tableProps={{
                stickyHeader: true,
              }}
              tableContainerProps={{
                sx: {
                  boxShadow: "none",
                  borderRadius: 0,
                  padding: "0",
                  boxSizing: "border-box",
                  maxHeight: "100%",
                  overflow: "auto",
                  backgroundColor: "transparent",
                  paddingRight:
                    (filteredData?.length || 0) > 12 ||
                    lazyDeliverablesQuery.isLoading ||
                    isLoadingSearch ||
                    (lazyDeliverablesQuery.isFetching &&
                      !lazyDeliverablesQuery.isFetchingNextPage)
                      ? "0.5rem"
                      : "0.25rem",

                  "&:hover": {
                    paddingRight: "0.25rem",
                  },
                },
              }}
            >
              {lazyDeliverablesQuery.isLoading ||
              isLoadingSearch ||
              (lazyDeliverablesQuery.isFetching &&
                !lazyDeliverablesQuery.isFetchingNextPage)
                ? Array(10)
                    .fill(0)
                    .map((_, item) => (
                      <TableRow key={item} sx={{backgroundColor: "#FFFFFF"}}>
                        <TableCell
                          sx={{
                            width: "30%",
                            fontSize: "0.875rem",
                            fontWeight: 400,
                            color: "#1A1A1A",
                            lineHeight: "1.192rem",
                            padding: "0.75rem 1rem",
                            height: "2.5rem",
                          }}
                        >
                          <Skeleton
                            variant="rectangular"
                            width="374px"
                            height={15}
                          />
                        </TableCell>
                        <TableCell
                          sx={{
                            width: "50%",
                            fontSize: "0.875rem",
                            fontWeight: 400,
                            color: "#1A1A1A",
                            lineHeight: "1.192rem",
                            padding: "0.75rem 1rem",
                            height: "2.5rem",
                          }}
                        >
                          <Skeleton
                            variant="rectangular"
                            width="100%"
                            height={15}
                          />
                        </TableCell>
                        <TableCell
                          sx={{
                            width: "10%",
                            fontSize: "0.875rem",
                            fontWeight: 400,
                            color: "#1A1A1A",
                            lineHeight: "1.192rem",
                            padding: "0.75rem 1rem",
                            height: "2.5rem",
                          }}
                        >
                          <Skeleton
                            variant="rectangular"
                            width="100%"
                            height={15}
                          />
                        </TableCell>
                        <TableCell
                          sx={{
                            width: "10%",
                            fontSize: "0.875rem",
                            fontWeight: 400,
                            color: "#1A1A1A",
                            lineHeight: "1.192rem",
                            padding: "0.75rem 1rem",
                            height: "2.5rem",
                          }}
                        >
                          <Skeleton
                            variant="rectangular"
                            width="100%"
                            height={15}
                          />
                        </TableCell>
                      </TableRow>
                    ))
                : lazyDeliverablesQuery.data &&
                  filteredData &&
                  filteredData?.map((deliverable, index) => (
                    <TableRow
                      key={deliverable.id}
                      ref={
                        index === (filteredData?.length || 0) - 2 &&
                        lazyDeliverablesQuery.data?.pages[0]?.rowCount &&
                        lazyDeliverablesQuery.data?.pages[0]?.rowCount > limit
                          ? ref
                          : null
                      }
                      sx={{
                        backgroundColor: "#FFFFFF",
                        transition: "all 0.2s ease-in-out",
                        cursor: "pointer",
                        "&:hover": {
                          backgroundColor: "#EEEEEE",
                        },
                      }}
                      onClick={() => handleNavigate(deliverable)}
                    >
                      <TableCell
                        sx={{
                          width: "30%",
                          fontSize: "0.875rem",
                          fontWeight: 400,
                          color: "#1A1A1A",
                          lineHeight: "1.192rem",
                          padding: "0.75rem 1rem",
                          height: "2.5rem",

                          svg: {
                            maxHeight: "20px",
                          },
                        }}
                      >
                        <Stack flexDirection="row" gap={1}>
                          {deliverable.strictlyConfidential ? (
                            <Tooltip
                              arrow
                              title={t("Strictly confidential")}
                              placement="right"
                            >
                              <div>
                                <ReactSVG
                                  src={
                                    deliverable.deliverableType === "dashboard"
                                      ? "/icons/file-icons/Dashboard-confidential.svg"
                                      : deliverable.deliverableType === "web"
                                      ? "/icons/file-icons/Web-report-confidential.svg"
                                      : `/icons${
                                          iconMap[
                                            getDeliverableExtension(
                                              deliverable.deliverableUrl || "",
                                            )
                                          ]
                                        }-confidential.svg`
                                  }
                                />
                              </div>
                            </Tooltip>
                          ) : (
                            <ReactSVG
                              src={
                                deliverable.deliverableType === "dashboard"
                                  ? "/icons/file-icons/Dashboard.svg"
                                  : deliverable.deliverableType === "web"
                                  ? "/icons/file-icons/Web-report.svg"
                                  : `/icons${
                                      iconMap[
                                        getDeliverableExtension(
                                          deliverable.deliverableUrl || "",
                                        )
                                      ]
                                    }.svg`
                              }
                            />
                          )}
                          {deliverable.deliverableName.length >= 37 ? (
                            <TextWithTooltip
                              tooltipProps={{arrow: true}}
                              tooltipStyle={{
                                "& .MuiTooltip-arrow": {
                                  // move arrow to the left
                                  left: "10px !important",
                                  transform: "none !important",
                                },
                              }}
                              text={deliverable.deliverableName}
                              textStyle={{
                                fontSize: "0.875rem",
                                fontWeight: 400,
                                color: "#1A1A1A",
                                lineHeight: "1.192rem",
                              }}
                              limit={37}
                            />
                          ) : (
                            deliverable.deliverableName
                          )}
                        </Stack>
                      </TableCell>
                      <TableCell
                        ref={index === 0 ? descriptionColumnRef : null}
                        sx={{
                          width: "50%",
                          fontSize: "0.875rem",
                          fontWeight: 400,
                          color: "#1A1A1A",
                          lineHeight: "1.192rem",
                          padding: "0.75rem 1rem",
                          height: "2.5rem",
                        }}
                      >
                        {deliverable.deliverableDescription.length >= 75 ? (
                          <TextWithTooltip
                            tooltipProps={{arrow: true}}
                            text={deliverable.deliverableDescription}
                            limit={descriptionCharacterLimit}
                            textStyle={{
                              fontSize: "0.875rem",
                              fontWeight: 400,
                              color: "#1A1A1A",
                              lineHeight: "1.192rem",
                            }}
                          />
                        ) : (
                          deliverable.deliverableDescription
                        )}
                      </TableCell>
                      <TableCell
                        sx={{
                          width: "10%",
                          fontSize: "0.875rem",
                          fontWeight: 400,
                          color: "#1A1A1A",
                          lineHeight: "1.192rem",
                          padding: "0.75rem 1rem",
                          height: "2.5rem",
                        }}
                      >
                        {!deliverable.lastUpdateTimestamp
                          ? ""
                          : moment(deliverable.lastUpdateTimestamp).format(
                              dateFormat,
                            )}
                      </TableCell>
                      <TableCell
                        sx={{
                          width: "10%",
                          fontSize: "0.875rem",
                          fontWeight: 400,
                          color: "#1A1A1A",
                          lineHeight: "1.192rem",
                          padding: "0.75rem 1rem",
                          height: "2.5rem",
                        }}
                      >
                        {moment(deliverable.createdTimestamp).format(
                          dateFormat,
                        ) || ""}
                      </TableCell>
                    </TableRow>
                  ))}
              {lazyDeliverablesQuery.isFetchingNextPage ? (
                <TableRow sx={{backgroundColor: "#FFFFFF"}}>
                  <TableCell
                    colSpan={4}
                    sx={{
                      fontSize: "0.875rem",
                      fontWeight: 400,
                      color: "#1A1A1A",
                      lineHeight: "1.192rem",
                      padding: "0.75rem 1rem",
                      height: "2.5rem",
                    }}
                  >
                    <Skeleton variant="rectangular" width="100%" height={15} />
                  </TableCell>
                </TableRow>
              ) : null}
            </Table>
          )}
        </Stack>
      </Stack>
    </Stack>
  )
}

export default ViewFolder
