import {type FC, Children} from "react"
import {useTheme} from "@mui/material/styles"
import {
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Box,
  Table,
  TableContainer,
  Pagination,
  TableSortLabel,
  OutlinedInput,
  Typography,
  Select,
  MenuItem,
  FormControl,
} from "@mui/material"
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined"
import Stack from "@mui/material/Stack"
import ArrowDropDown from "@mui/icons-material/ArrowDropDown"
import {TableComponentProps} from "./types"
import Callout from "../../atoms/callout/Callout"
import {ColumnCellWrapper} from "./table.styles"
import {useTranslation} from "react-i18next"

const CTable: FC<TableComponentProps> = ({
  headings = [],
  pagination = null,
  children = null,
  noneFound = "No matches found",
  sortState = null,
  filterState = null,
  limit = 15,
  rowCount = 0,
  style,
  tableContainerProps,
  tableProps,
}) => {
  const theme = useTheme()
  const {t} = useTranslation()
  return (
    <Box
      style={{
        paddingTop: "0.5rem",
        ...style,
      }}
    >
      <TableContainer component={Paper} {...tableContainerProps}>
        <Table style={{height: "48px"}} {...tableProps}>
          <TableHead>
            <TableRow
              sx={{
                backgroundColor: "#F4F4F4",
                ".MuiTableCell-stickyHeader": {
                  backgroundColor: "#F4F4F4",
                },
              }}
            >
              {headings.map(
                ({name, field, isSortable, filter, wide, sx}, index) => (
                  <TableCell
                    key={`heading-${name}-${index}`}
                    sx={{
                      color: "#242D35",
                      width: wide ? "40%" : "25%",
                      padding: "0.75rem 1rem",
                      height: "2.5rem",
                      ...sx,
                    }}
                  >
                    <ColumnCellWrapper>
                      <Box>
                        {!isSortable && t(name)}
                        {isSortable && sortState && (
                          <TableSortLabel
                            sx={{
                              "&.MuiTableSortLabel-root": {
                                color: "#242D35",
                                display: "flex",
                                flexDirection: "row",
                                gap: "0.625rem",
                              },
                              "&.Mui-active": {
                                color: "#242D35",
                              },
                              ".MuiTableSortLabel-icon": {
                                color: "#242D35 !important",
                                margin: 0,
                                scale: "1.5 !important",
                              },
                            }}
                            active={
                              isSortable && sortState.sortBy.includes(field)
                            }
                            direction={
                              sortState.sortBy.includes("-") ? "desc" : "asc"
                            }
                            IconComponent={ArrowDropDown}
                            onClick={() => {
                              if (!sortState.sortBy.includes(field)) {
                                sortState.setSortBy(field)
                                return
                              }

                              if (sortState.sortBy === field)
                                sortState.setSortBy(`-${field}`)
                              else sortState.setSortBy(field)
                            }}
                          >
                            {t(name)}
                          </TableSortLabel>
                        )}
                        {filter && (
                          <Callout name="search..." trigger={() => filter.icon}>
                            <OutlinedInput
                              fullWidth
                              type={filter.type}
                              placeholder="Search..."
                              defaultValue={filterState.filters[field]}
                              onChange={({target: {value}}) => {
                                const uFilters = {...filterState.filters}
                                uFilters[field] = value
                                filterState.setFilters(uFilters)
                              }}
                            />
                          </Callout>
                        )}
                      </Box>
                    </ColumnCellWrapper>
                  </TableCell>
                ),
              )}
            </TableRow>
          </TableHead>
          {Children.toArray(children).length !== 0 &&
          Children.count(children) !== 0 ? (
            <TableBody>{children}</TableBody>
          ) : (
            <TableBody sx={{backgroundColor: "#fff"}}>
              <TableRow sx={{textAlign: "center"}}>
                <TableCell
                  colSpan={headings.length}
                  style={{textAlign: "center"}}
                >
                  {noneFound}
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
      </TableContainer>
      {pagination && (
        <Box
          my={3}
          display="flex"
          justifyContent="right"
          alignItems="center"
          gap={2}
        >
          <Typography
            variant="body2"
            sx={{
              color: theme.palette.text.secondary,
            }}
          >
            Showing {pagination.page * limit - limit + 1}-
            {rowCount < pagination.page * limit
              ? rowCount
              : pagination.page * limit}{" "}
            of {rowCount}
          </Typography>
          <FormControl
            sx={{
              "& .MuiSelect-select": {
                padding: "0.35rem 0.75rem",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "#226CA5",
              },
              "& .MuiSelect-icon": {
                color: theme.palette.text.primary,
              },
            }}
          >
            <Select
              name="page"
              value={pagination.page}
              defaultValue={1}
              onChange={(ev) => {
                // @ts-ignore
                pagination.onChange(ev, ev.target.value)
              }}
              IconComponent={ExpandMoreOutlinedIcon}
            >
              {[...Array(pagination.totalPages)].map((_, index) => (
                <MenuItem
                  key={`page-${index}`}
                  value={index + 1}
                  sx={{
                    "&.Mui-selected": {
                      backgroundColor: theme.palette.primary.main,
                      color: theme.palette.common.white,
                    },
                    "&.MuiListItem-root": {
                      backgroundColor: theme.palette.common.white,
                      color: theme.palette.text.primary,
                    },
                    "&.MuiListItem-root:hover": {
                      backgroundColor: theme.palette.primary.main,
                      color: theme.palette.common.white,
                    },
                  }}
                >
                  {index + 1}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Stack spacing={2}>
            <Pagination
              count={pagination.totalPages}
              page={pagination.page}
              onChange={pagination.onChange}
              sx={{
                "& .MuiPaginationItem-root": {
                  color: theme.palette.text.primary,
                  borderColor: "#226CA5",
                },
                "& .Mui-selected.MuiPaginationItem-page": {
                  color: theme.palette.common.white,
                  backgroundColor: "#226CA5",
                },
                "& .MuiPagination-ul li button": {
                  borderWidth: "1px",
                  borderColor: "#226CA5",
                  borderStyle: "solid",
                },
                "& .MuiPagination-ul li:first-of-type button": {
                  borderColor: "transparent",
                  marginLeft: "0",
                },
                "& .MuiPagination-ul li:last-of-type button": {
                  borderColor: "transparent",
                  marginRight: "0",
                },
              }}
              shape="rounded"
              color="primary"
              siblingCount={0}
              boundaryCount={1}
            />
          </Stack>
        </Box>
      )}
    </Box>
  )
}

export default CTable
