import {ReactNode} from "react"
import {ReactSVG} from "react-svg"

type SideBarLink = {
  type: "link"
  title: string
  icon: ReactNode
  link?: string
  match?: string
  action?: (param: any) => void
}

type SideBarComponent = {
  type: "component"
  title: string
  component: ReactNode
}

export type SideBarItem = SideBarLink | SideBarComponent

export const sideBarItems: SideBarItem[] = [
  {
    type: "link",
    title: "Projects",
    icon: (
      <ReactSVG
        src="/icons/projects-outlined.svg"
        onError={(error) => console.log(error)}
      />
    ),
    link: "/projects",
  },
  {
    type: "link",
    title: "Ask an expert",
    icon: <ReactSVG src="/icons/ask-expert.svg" />,
    action: (setOpenModal) => setOpenModal(true),
  },
]
