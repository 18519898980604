import {type FC, useState} from "react"
import {Box, Link, Stack, SxProps, Typography} from "@mui/material"
import {footerLinks} from "./footerLinks"
import {TermsAndConditionsModal} from "../"
import {useTranslation} from "react-i18next"

type Props = {
  sx?: SxProps
}

const Footer: FC<Props> = (props) => {
  const {sx} = props
  const [isTCModalOpen, setIsTCModalOpen] = useState<boolean>(false)
  const {t} = useTranslation()

  return (
    <Stack
      id="footer"
      component="footer"
      sx={{
        backgroundColor: "#EEEEEE",
        color: "#7A7A79",
        position: "relative",
        zIndex: 1000,
        padding: "1rem 2rem",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        height: "3.5rem",
        ...sx,
      }}
    >
      <Typography
        color="inherit"
        fontWeight={400}
        lineHeight="1.25rem"
        fontSize="0.875rem"
      >
        &copy; Control Risks Group Holdings LTD Registration no.01548306
      </Typography>
      <Stack
        flexDirection="row"
        gap="4rem"
        justifyContent="end"
        alignItems="center"
      >
        {footerLinks.map((item) =>
          item.showModal ? (
            <Box
              role="button"
              component="button"
              key={item.text}
              onClick={() => item.showModal && item.showModal(setIsTCModalOpen)}
              sx={{
                p: 0,
                color: "#6A6A69",
                fontWeight: 400,
                fontSize: "0.875rem",
                lineHeight: "1rem",
                textTransform: "none",
                textDecoration: "underline",
                textDecorationColor: "#6A6A69",
                backgroundColor: "none",
                border: "none",
                cursor: "pointer",
              }}
            >
              {t(item.text)}
            </Box>
          ) : (
            <Link
              key={item.text}
              href={item.link}
              color="#6A6A69"
              underline="none"
              target="_blank"
              rel="noreferrer"
              fontWeight="400"
              fontSize="0.875rem"
              sx={{
                lineHeight: "1rem",
                textTransform: "none",
                textDecoration: "underline",
                textDecorationColor: "#6A6A69",
              }}
            >
              {t(item.text)}
            </Link>
          ),
        )}
      </Stack>

      <TermsAndConditionsModal
        isOpen={isTCModalOpen}
        onClose={() => setIsTCModalOpen(false)}
      />
    </Stack>
  )
}

Footer.displayName = "Footer"

export default Footer
