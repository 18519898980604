import {UseQueryResult} from "@tanstack/react-query"
import {ProjectAssignmentModel} from "../types"

export const projectOwnerNames = (
  projectQuery: UseQueryResult<ProjectAssignmentModel, Error>,
) => {
  let ownerString = ""

  projectQuery.data?.projectAssignmentOwners.forEach((p, index) => {
    if (
      index === projectQuery.data?.projectAssignmentOwners.length - 1 &&
      projectQuery.data?.projectAssignmentOwners.length > 1
    ) {
      ownerString += " and "
    } else if (
      index !== 0 &&
      index !== projectQuery.data?.projectAssignmentOwners.length - 1
    ) {
      ownerString += ", "
    }
    ownerString += p.firstname + " " + p.surname
  })

  return ownerString
}
