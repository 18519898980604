import type {AvatarProps} from '@mui/material'

const stringToColor = (string: string, index: number) => {
  let hash = 0
  let i

  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash)
  }

  const allowedColors = ['#EA746D', '#226CA6', '#008475', '#5199C2', '#7C4F92']

  let color = ''

  switch (index) {
    case 0:
      color = allowedColors[0]
      break
    case 1:
      color = allowedColors[1]
      break
    case 2:
      color = allowedColors[2]
      break
    default:
      color = allowedColors[0]
      break
  }

  return color
}

export const stringAvatar = (value: string, index: number): AvatarProps => {
  const name = value
  const fullName = name.split(' ')
  if (fullName.length === 1) {
    return {
      sx: {
        bgcolor: stringToColor(name, index),
        height: '24px',
        width: '24px',
        color: '#FFFFFF',
        fontSize: '10px',
        fontWeight: '600',
        lineHeight: '20px',
        textTransform: 'uppercase',
      },
      children: `${name[0]}` || '',
    }
  } else if (fullName.length >= 2) {
    return {
      sx: {
        bgcolor: stringToColor(name, index),
        height: '24px',
        width: '24px',
        color: '#FFFFFF',
        fontSize: '10px',
        fontWeight: '600',
        lineHeight: '20px',
        textTransform: 'uppercase',
      },
      children: `${fullName[0][0]}${fullName[1][0]}` || '',
    }
  }
  return {
    sx: {
      bgcolor: stringToColor(name, index),
      height: '24px',
      width: '24px',
      color: '#FFFFFF',
      fontSize: '10px',
      fontWeight: '600',
      lineHeight: '20px',
      textTransform: 'uppercase',
    },
    children: '',
  }
}
