import {yupResolver} from "@hookform/resolvers/yup"
import {
  Box,
  CircularProgress,
  Divider,
  IconButton,
  Modal,
  Typography,
} from "@mui/material"
import {Stack} from "@mui/system"
import {FC, Fragment, useEffect, useState} from "react"
import {Controller, useForm} from "react-hook-form"
import * as yup from "yup"
import {Input, NewButton} from "../../atoms"
import {regexValidators} from "../../../helpers"
import {CloseIcon, SuccessIcon} from "../../../assets/icons"
import {RichTextField} from "../../molecules/richTextEditor/RichTextField"
const {stringIsLink, stringIsScript, stringIsNotSQL} = regexValidators()
import {appInsights} from "../../../config/appInsights"
import {clientsEndpoints} from "../../../services/api/clients"
import {useMutation, useQuery} from "@tanstack/react-query"
import {
  ClientModel,
  PagedResult,
  ProjectAssignmentModel,
  ProjectManager,
  Token,
} from "../../../types"
import {FabricEvent} from "../../../services/types/event"
import {utilitiesEndpoints} from "../../../services/api/utilities"
import {notificationEndpoints} from "../../../services/api/notification"
import {ProjectQuestionEmail} from "../../../services/types/notification"
import {useConfigProvider} from "../../../config"
import {decodeToken} from "react-jwt"
import {toast} from "react-toastify"
import filterEmojis from "../../../helpers/filterEmojis"
import DOMPurify from "dompurify"

type ProjectQuestionModal = {
  open: boolean
  onClose: () => void
  selectedProject: ProjectAssignmentModel | undefined
  projectManager: ProjectManager | undefined
}

const MAX_DESCRIPTION_CHARS = 4000

const schema = yup.object({
  Subject: yup
    .string()
    .min(3, "Please provide a valid subject")
    .max(
      100,
      "Maximum character limit reached, please continue in description space",
    )
    .required("Subject is required"),
  RelatesTo: yup
    .string()
    .min(3, "Please provide a valid subject")
    .max(
      100,
      "Maximum character limit reached, please continue in description space",
    )
    .required("Subject is required"),
  Description: yup
    .string()
    .min(3, "Please provide a valid description")
    .max(
      MAX_DESCRIPTION_CHARS,
      `Maximum character limit reached, please send a follow up message`,
    )
    .matches(stringIsLink, "Links are not allowed")
    .matches(stringIsScript, "Invalid text format")
    .matches(stringIsNotSQL, "Invalid text format")
    .required("Description is required"),
})

type ProjectQuestionFrom = yup.InferType<typeof schema>

const ProjectQuestionModal: FC<ProjectQuestionModal> = (props) => {
  const {open, onClose, selectedProject, projectManager} = props
  const {token} = useConfigProvider()
  const {getClientByProjectId} = clientsEndpoints()
  const {sendFabricEvent} = utilitiesEndpoints()
  const {sendProjectQuestionEmail} = notificationEndpoints()
  const [relatesTo, setRelatesTo] = useState<string>("")
  const [descriptionHtmlContent, setDescriptionHtmlContent] =
    useState<string>("")

  const decoded = decodeToken<Token>(token)
  const {
    control,
    handleSubmit,
    formState: {errors},
    // reset,
    watch,
    // setError,
    // clearErrors,
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onBlur",
    defaultValues: {
      RelatesTo: relatesTo,
    },
  })

  const clientQuery = useQuery<PagedResult<ClientModel>>({
    enabled: !!selectedProject && !!selectedProject?.id,
    queryKey: ["getClientByProjectId", selectedProject?.id],
    queryFn: () => getClientByProjectId(selectedProject?.id || ""),
  })

  const fabricEventMutation = useMutation<any, Error, FabricEvent>({
    mutationFn: (payload) => sendFabricEvent(payload),
    mutationKey: ["sendNotification"],
  })

  const sendProjectQuestionMutation = useMutation<
    any,
    Error,
    ProjectQuestionEmail
  >({
    mutationFn: (payload) => sendProjectQuestionEmail(payload),
    mutationKey: ["sendProjectQuestion"],
  })

  const relatesToValue = watch("RelatesTo")
  const subject = watch("Subject")

  useEffect(() => {
    setRelatesTo(
      selectedProject?.projectAssignmentName ||
        selectedProject?.projectAssignmentDisplayName ||
        "",
    )
  }, [
    selectedProject?.projectAssignmentName,
    selectedProject?.projectAssignmentDisplayName,
  ])

  useEffect(() => {
    setValue("RelatesTo", relatesTo)
  }, [relatesTo])

  const onSubmit = async (data: ProjectQuestionFrom) => {
    const client = clientQuery.data?.results[0]
    const description = filterEmojis(descriptionHtmlContent)
    const toAddresses: string[] =
      selectedProject?.projectAssignmentOwners.map((u) => {
        if (u.userId !== projectManager?.projectManagerId) return u.emailAddress

        return ""
      }) || []

    var projectQuestionPayload = {
      notificationType: "ProjectQuestion",
      clientId: client?.id || "",
      clientName: client?.clientName || "",
      projectId: selectedProject?.id || "",
      projectName:
        selectedProject?.projectAssignmentDisplayName ||
        selectedProject?.projectAssignmentName ||
        "",
      customerId: decoded?.sub || "",
      reference: relatesToValue,
      subject: subject,
      htmlContent: DOMPurify.sanitize(description || ""),
      user: {
        userEmail: decoded?.email || "",
        userFirstname: decoded?.firstname,
        userOwnername: `${decoded?.firstname} ${decoded?.surname}`,
      },
      projectManagerId: projectManager?.projectManagerId || "",
      toAddresses: [...toAddresses],
    } satisfies ProjectQuestionEmail

    var response = await sendProjectQuestionMutation.mutateAsync(
      projectQuestionPayload,
    )

    if (response === 200) {
      toast.success("Thank you, your question has been submitted.", {
        autoClose: 3000,
        style: {
          borderLeftColor: "#58A733",
        },
        icon: SuccessIcon,
      })
    }

    appInsights.trackEvent({
      name: "PROJECT_QUESTION",
      properties: {
        Client: client?.clientName,
        Subject: data.Subject,
        Description: data.Description,
        RelatesTo: data.RelatesTo,
      },
    })

    const fabricEventPayload = {
      type: "project_question",
      targetId: selectedProject?.id || "",
      targetName: selectedProject?.projectAssignmentName || "",
    } satisfies FabricEvent

    await fabricEventMutation.mutateAsync(fabricEventPayload)

    onClose()
  }

  return (
    <Modal
      open={open}
      onClose={onClose}
      sx={{
        "&.MuiModal-root": {
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
      }}
    >
      <Stack
        component="form"
        sx={{
          backgroundColor: "white",
          outline: "none",
          borderRadius: "4px",
          width: "712px",
          height: "690px",
        }}
        onSubmit={handleSubmit(onSubmit)}
      >
        {clientQuery.isLoading ? (
          <Stack justifyContent={"center"} alignItems={"center"} height="100%">
            <CircularProgress />
          </Stack>
        ) : (
          <Fragment>
            <Stack
              padding="16px"
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{
                borderTop: "solid 7px #053747",
                borderTopLeftRadius: "4px",
                borderTopRightRadius: "4px",
              }}
            >
              <Typography fontSize={20} fontWeight={600}>
                Project question
              </Typography>
              <IconButton onClick={onClose}>
                <CloseIcon />
              </IconButton>
            </Stack>
            <Divider />
            <Stack padding="16px" direction="row" spacing={"16px"}>
              <Box flex={4}>
                <Controller
                  control={control}
                  name="Subject"
                  render={({field}) => (
                    <Input
                      aria-label="subject"
                      label="Subject"
                      error={errors.Subject?.message}
                      required
                      counter
                      limit={100}
                      textFieldProps={{
                        id: "subject",
                        placeholder: "Type subject",
                        fullWidth: true,
                        required: true,
                        InputProps: {
                          sx: {
                            fontSize: "14px",
                            ".MuiOutlinedInput-input.Mui-disabled": {
                              backgroundColor: "#BDBDBC",
                              color: "#242D35",
                              opacity: "unset",
                              "-webkit-text-fill-color": "#242D35",
                            },
                          },
                        },
                        ...field,
                      }}
                    />
                  )}
                />
              </Box>
              <Box flex={2}>
                <Controller
                  control={control}
                  name="RelatesTo"
                  render={({field}) => (
                    <Input
                      aria-label="relates-to-input"
                      textFieldProps={{
                        id: "relatesTo",
                        placeholder: "Type relates to",
                        fullWidth: true,
                        required: true,
                        InputProps: {
                          sx: {
                            fontSize: "14px",
                            ".MuiOutlinedInput-input.Mui-disabled": {
                              backgroundColor: "#BDBDBC",
                              color: "#242D35",
                              opacity: "unset",
                              "-webkit-text-fill-color": "#242D35",
                            },
                          },
                        },
                        ...field,
                      }}
                      error={errors.RelatesTo?.message}
                      formGroupProps={{}}
                      label="Relates to"
                      required
                      disabled
                      ellipsis
                    />
                  )}
                />
              </Box>
            </Stack>
            <Stack paddingX="16px">
              <Controller
                control={control}
                name="Description"
                render={({field}) => (
                  <RichTextField
                    aria-label="description-editor"
                    formError={errors.Description?.message}
                    {...field}
                    onChange={(value) => {
                      setDescriptionHtmlContent(value.html)
                      field.onChange(value.text)
                    }}
                    editorContainerStyle={{
                      height: "331px",
                    }}
                    maxCharacters={MAX_DESCRIPTION_CHARS}
                    placeholder="Tell us about your enquiry"
                    label="Description"
                  />
                )}
              />
            </Stack>
            <Stack
              flexDirection="row"
              gap={"5px"}
              justifyContent="end"
              padding="16px"
            >
              <NewButton
                aria-label="cancel-form"
                variant="outline"
                color="primary"
                size="medium"
                text="Cancel"
                onClick={onClose}
                sx={{
                  width: "88px",
                  backgroundColor: "white",
                  color: "#053747",
                  textDecoration: "underline",
                  textUnderlineOffset: "5px",
                  border: "unset",
                  "&:hover": {
                    borderBottomWidth: "2px",
                    bgcolor: "transparent",
                    paddingTop: "2px",
                    textDecoration: "underline",
                  },
                  "&:active": {
                    backgroundColor: "transparent",
                  },
                }}
              />
              <NewButton
                aria-label="submit-form"
                variant="filled"
                color="primary"
                size="medium"
                text="Submit"
                type="submit"
                loading={sendProjectQuestionMutation.isPending}
                sx={{
                  width: "136px",
                }}
              />
            </Stack>
          </Fragment>
        )}
      </Stack>
    </Modal>
  )
}

export default ProjectQuestionModal
