import {Stack, Typography} from "@mui/material"
import {DashboardIcon, FileIcon, FolderIcon} from "../../../assets/icons/Icons"
import moment from "moment"
import {Tooltip} from "../Tooltip"
import {truncateText} from "../../../helpers/truncateText"
import {BoxWithScrollbar} from "../../molecules/boxWithScrollbar/BoxWithScrollbar"
import {useTranslation} from "react-i18next"

type TitleProps = {
  text: string
  hover?: boolean
}

const Title = ({text}: TitleProps) => (
  <Typography
    fontSize={16}
    fontWeight={600}
    noWrap
    sx={{color: "#4e555c", textDecoration: "none"}}
  >
    {truncateText(text, 60)}
  </Typography>
)

export const Description = ({text}: {text: string}) => {
  return (
    <BoxWithScrollbar
      maxHeight={70}
      sx={{
        flex: 4,
        display: ["-webkit-box", "box"],
        WebkitBoxOrient: "vertical",
        WebkitLineClamp: 3,
        wordBreak: "break-word",
        whiteSpace: "pre-line",
      }}
    >
      <Typography fontSize={14} fontWeight={400} color={"#4F5B67"}>
        {truncateText(text, 330)}
      </Typography>
    </BoxWithScrollbar>
  )
}

export const DateCreated = ({dateTime}: {dateTime: string}) => {
  const {t} = useTranslation()
  return (
    <Stack
      flex={1}
      justifyContent={"flex-end"}
      textAlign={"right"}
      direction={"row"}
      spacing={1}
      mt={2}
    >
      <Typography fontSize={14} color={"#242D35"}>
        {t("Created")}:
      </Typography>
      <Typography fontSize={14} color={"#4F5B67"}>
        {moment(dateTime).format("DD/MM/yyyy")}
      </Typography>
    </Stack>
  )
}

type HeaderProps = {
  title: string
  hover?: boolean
  folderCount: number
  filesCount: number
  dashboardCount: number
}
export const Header = ({
  title,
  hover,
  folderCount,
  filesCount,
  dashboardCount,
}: HeaderProps) => {
  const {t} = useTranslation()
  return (
    <Stack
      flex={1}
      direction="row"
      justifyContent="space-between"
      alignItems="center"
    >
      <Title text={title} hover={hover} />
      <Stack spacing={1} direction="row" color="#7E8081">
        <Tooltip title={t("Folders")} arrow placement="right">
          <Stack direction="row" gap={0.5}>
            <FolderIcon />
            <Typography fontSize={14} fontWeight={400}>
              {folderCount}
            </Typography>
          </Stack>
        </Tooltip>
        <Tooltip title={t("Dashboards")} arrow placement="right">
          <Stack direction="row" gap={0.5}>
            <DashboardIcon />
            <Typography fontSize={14} fontWeight={400}>
              {dashboardCount}
            </Typography>
          </Stack>
        </Tooltip>
        <Tooltip title={t("Files")} arrow placement="right">
          <Stack direction="row" gap={0.5}>
            <FileIcon />
            <Typography fontSize={14} fontWeight={400}>
              {filesCount}
            </Typography>
          </Stack>
        </Tooltip>
      </Stack>
    </Stack>
  )
}
