import {FC, useEffect, useState} from "react"
import {
  Button,
  CircularProgress,
  InputBase,
  Stack,
  Typography,
} from "@mui/material"
import {FileRejection, useDropzone} from "react-dropzone"
import {
  CancelIcon,
  QuestionIcon,
  ErrorIcon,
  WarningIcon,
} from "../../../assets/icons"
import {TextWithTooltip} from "../textWithTooltip"
import {ReactSVG} from "react-svg"
import {Tooltip, ErrorTooltip} from "../../atoms"
import {
  getDeliverableExtension,
  iconMap,
} from "../../../helpers/getDeliverableExtension"
import {toast} from "react-toastify"
import {yupResolver} from "@hookform/resolvers/yup"
import * as yup from "yup"
import {
  Control,
  Controller,
  useForm,
  UseFormResetField,
  UseFormSetValue,
  UseFormUnregister,
} from "react-hook-form"
import {useParams} from "react-router-dom"
import {
  ClientModel,
  CreateDeliverablePayload,
  DeliverableModel,
  //DeliverableModel,
  PagedResult,
  ProjectAssignmentModel,
  ProjectManager,
} from "../../../types"
import moment from "moment"
import {decodeToken} from "react-jwt"
import {useConfigProvider} from "../../../config/ConfigProvider"
import {Token} from "../../../types/Token"
import {
  QueryClient,
  UseQueryResult,
  useMutation,
  useQuery,
} from "@tanstack/react-query"
import {deliverableEndpoints} from "../../../services/api/deliverables"
import filterEmojis from "../../../helpers/filterEmojis"
import {useTranslation} from "react-i18next"
import {ClientUploadNotification} from "../../../services/types/notification"
import {notificationEndpoints} from "../../../services/api/notification"
import {projectsEndpoints} from "../../../services"
import {utilitiesEndpoints} from "../../../services/api/utilities"
import {FabricEvent} from "../../../services/types/event"
import {appInsights} from "../../../config/appInsights"

const schema = yup.object().shape({
  files: yup.array().of(
    yup.object().shape({
      deliverableFile: yup.mixed().required(),
      deliverableDescription: yup
        .string()
        // .required("Mandatory field")
        .min(3, "Minimum 3 characters")
        .max(330, "Maximum 330 characters"),
    }),
  ),
})
export type DeliverableFileFormValues = yup.InferType<typeof schema>

const supportedFormats: string[] = [
  "docx",
  "docm",
  "dot",
  "dotx",
  "dotm",
  "xlsx",
  "xlsm",
  "xlt",
  "xltx",
  "xltm",
  "xlsb",
  "pptx",
  "pptm",
  "pot",
  "pps",
  "potm",
  "potx",
  "ppsx",
  "ppsm",
  "svg",
  "pdf",
  "jpg",
  "png",
  "jfif",
  "tif",
  "mp4",
  "ogg",
  "ogv",
  "webm",
  "csv",
  "txt",
  "dwg",
  "dwf",
  "dxf",
  "dgn",
  "rvt",
  "rtf",
  "fdf",
  "xfdf",
  "eml",
  "msg",
  "pub",
  "vsdx",
]

interface DeliverableInfoProps {
  file: File
  setFiles: any
  index: number
  control: Control
  errors: any
  setError: any
  getValues: any
  clearErrors: any
  trigger: any
  resetField: UseFormResetField<DeliverableFileFormValues>
  setValue: UseFormSetValue<DeliverableFileFormValues>
  unregister: UseFormUnregister<DeliverableFileFormValues>
}

const DeliverableInfo: FC<DeliverableInfoProps> = ({
  file,
  setFiles,
  index,
  control,
  errors,
  getValues,
  trigger,
  clearErrors,
  unregister,
}: DeliverableInfoProps) => {
  const handleBlur = async (field: string) => {
    const fieldValue = getValues(field)

    if (fieldValue === "") {
      clearErrors(field)
    } else {
      await trigger(field)
    }
  }

  const checkIfErrorActive = () => {
    let errorArray: string[] = []
    if (errors?.files) {
      if (errors?.files.length && errors?.files.length > 0) {
        for (let i = 0; i < errors.files.length; i++) {
          if (errors.files[i]?.deliverableDescription?.ref?.name) {
            errorArray.push(
              (errors.files[i]?.deliverableDescription?.ref?.name as
                | string
                | undefined) ?? "",
            )
          }
        }
      }
    }
    if (errorArray.find((str) => str.includes(index.toString()))) {
      return true
    }
    return false
  }

  return (
    <Stack>
      <Stack
        direction="row"
        justifyContent={"space-between"}
        alignContent={"center"}
        alignItems={"center"}
      >
        <Stack direction="row" spacing={1}>
          <ReactSVG
            src={`/icons${
              iconMap[getDeliverableExtension(file?.name || "")]
            }.svg`}
          />
          <TextWithTooltip
            text={file?.name || ""}
            limit={50}
            textStyle={{
              fontWeight: "400",
              fontSize: "14px",
              color: "#1A1A1A",
              overflow: "hidden",
            }}
          />
        </Stack>
        <Stack
          direction="row"
          sx={{width: "15%", minWidth: "50px"}}
          alignContent={"center"}
          justifyContent={"space-between"}
          alignItems={"center"}
          spacing={1}
        >
          <Typography fontWeight={400} fontSize={12} color="#595958">
            {Math.round(file.size / 1000) < 1000 ? (
              <>{Math.round(file.size / 1000)} KB</>
            ) : (
              <>
                {Math.round((file.size / 1000000) * 100) / 100}
                MB
              </>
            )}
          </Typography>
          <Stack
            sx={{cursor: "pointer"}}
            onClick={() => {
              setFiles((prevFiles: File[]) => {
                unregister(`files.${index}.deliverableDescription`)
                unregister(`files.${index}.deliverableFile`)
                if (prevFiles.length === 1) {
                  return []
                }
                const newFiles = prevFiles.map((f, i) => {
                  if (i === index) {
                    return null
                  }
                  return f
                })
                return newFiles
              })
            }}
          >
            <CancelIcon fill={"#DB0D00"} />
          </Stack>
        </Stack>
      </Stack>
      <Stack
        sx={{
          border: checkIfErrorActive()
            ? "1px solid #DB0D00"
            : "1px solid #BDBDBC",
          borderRadius: "0.25rem",
          height: "32px",
        }}
        direction="row"
        padding={1}
        alignContent={"center"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Controller
          control={control}
          name={"files[" + index + "].deliverableDescription"}
          render={({field}) => (
            <InputBase
              name={"files[" + index + "].deliverableDescription"}
              placeholder="Description"
              fullWidth={true}
              value={field.value || ""}
              onChange={(event) => {
                event.target.value = filterEmojis(event.target.value)
                field.onChange(event)
              }}
              sx={{
                ".MuiInputBase-input": {
                  color: checkIfErrorActive() ? "#DB0D00" : "#7A7A79",
                },
                width: "95%",
              }}
              onBlur={() =>
                handleBlur("files[" + index + "].deliverableDescription")
              }
              // onFocus={handleFocus}
            />
          )}
        />
        <Controller
          control={control}
          name={"files[" + index + "].deliverableFile"}
          defaultValue={file}
          render={({field}) => <></>}
        />
        {checkIfErrorActive() ? (
          <ErrorTooltip
            arrow={true}
            title={errors.files[index]?.deliverableDescription?.message}
            placement="left"
          >
            <Stack>
              <WarningIcon fill={"#DB0D00"} />
            </Stack>
          </ErrorTooltip>
        ) : (
          <Tooltip arrow={true} title={"3-330 characters"} placement="left">
            <Stack>
              <QuestionIcon fill={"#6A6A69"} width={16} height={16} />
            </Stack>
          </Tooltip>
        )}
      </Stack>
    </Stack>
  )
}

interface DropzoneProps {
  files: File[]
  setFiles: any
  setOpenCancelModal: any
  client?: PagedResult<ClientModel>
  projectQuery?: UseQueryResult<ProjectAssignmentModel, Error>
  forceCloseDrawer?: any
  uploadInProgress?: boolean
  setUploadInProgress?: any
}
const Dropzone: FC<DropzoneProps> = ({
  files,
  setFiles,
  setOpenCancelModal,
  client,
  projectQuery,
  forceCloseDrawer,
  uploadInProgress,
  setUploadInProgress,
}: DropzoneProps) => {
  const [errorToasts, setErrorToasts] = useState<string[]>([])
  const [rejectedFiles, setRejectedFiles] = useState<FileRejection[]>([])
  const {token, serverUrl} = useConfigProvider()
  const decoded = decodeToken<Token>(token)
  const {createDeliverable} = deliverableEndpoints()
  const {sendClientUploadNotification} = notificationEndpoints()
  const {getProjectManager} = projectsEndpoints()
  const {sendFabricEvent} = utilitiesEndpoints()
  const project = projectQuery?.data
  const queryClient = new QueryClient()
  //const [/*validFiles*/, setValidFiles] = useState<File[]>([])
  const {id} = useParams<{
    id: string
  }>()
  const {t} = useTranslation()

  const {
    control,
    handleSubmit,
    setError,
    getValues,
    clearErrors,
    resetField,
    setValue,
    unregister,
    trigger,
    formState: {errors},
  } = useForm<DeliverableFileFormValues>({
    resolver: yupResolver(schema),
    mode: "onBlur",
  })

  const {getRootProps, getInputProps} = useDropzone({
    maxFiles: 10,
    onDrop: (acceptedFiles, fileRejections) => {
      if (
        files?.filter((f) => f).length +
          acceptedFiles.length +
          fileRejections.length >
        10
      ) {
        // Reject excess files

        setErrorToasts(["You can upload up to 10 files at a time"])
        return
      } else {
        setFiles((prevFiles: File[]) => [...prevFiles, ...acceptedFiles])
      }
      setErrorToasts([])
      //setValidFiles(acceptedFiles)
      setRejectedFiles(fileRejections)
    },
    validator: fileValidations,
    disabled: files?.filter((f) => f).length === 10,
  })

  const projectManagerQuery = useQuery<ProjectManager>({
    queryFn: () => getProjectManager(serverUrl, project?.id || ""),
    queryKey: ["getProjectManager", project?.id],
  })

  const sendFabricEventMutation = useMutation<any, Error, FabricEvent>({
    mutationFn: (payload) => sendFabricEvent(payload),
    mutationKey: ["sendFabricEvent"],
  })

  const sendClientUploadNotificationMutation = useMutation<
    any,
    Error,
    ClientUploadNotification
  >({
    mutationFn: (payload) => sendClientUploadNotification(payload),
    mutationKey: ["sendClientUploadNotification"],
  })

  function fileValidations(file: File) {
    const fileFormat = file.name.split(".").pop()?.toLowerCase()
    if (!supportedFormats.includes(fileFormat ? fileFormat : "")) {
      return {
        code: "format-not-supported",
        message: `${file.name} format is not supported`,
      }
    }
    if (file.size > 90000000) {
      return {
        code: "file-too-large",
        message: `${file.name} exceeds max. size`,
      }
    }

    return null
  }

  useEffect(() => {
    const errorArray: string[] = []
    rejectedFiles.forEach((f) => {
      errorArray.push(f.errors[0].message)
    })
    setErrorToasts(errorArray)
  }, [rejectedFiles])

  useEffect(() => {
    if (files?.filter((f) => f).length + errorToasts.length > 10) {
      toast.error("You can upload up to 10 files at a time", {
        autoClose: 5000,
        icon: ErrorIcon,
      })
    } else {
      errorToasts.forEach((toastMessage) => {
        toast.error(toastMessage, {
          autoClose: 5000,
          icon: ErrorIcon,
        })
      })
    }
  }, [errorToasts])

  const handleFilesSubmit = async (data: DeliverableFileFormValues) => {
    const payload: CreateDeliverablePayload[] = []
    setUploadInProgress(true)
    data?.files
      ?.filter((f) => f)
      .forEach((element: any) => {
        const p = {
          projectAssignmentId: id!,
          clientId: client?.results?.[0]?.id,
          userEmail: decoded?.email || "",
          deliverableDescription:
            element.deliverableDescription ||
            element.deliverableFile.name.split(".").slice(0, -1).join("."),
          deliverableName: element.deliverableFile.name
            .split(".")
            .slice(0, -1)
            .join("."),
          deliverableType: "report",
          expiryDate: moment(project?.expiryDate).format("MM/DD/YYYY"),
          deliverableSource: getDeliverableExtension(
            element.deliverableFile.name || "",
          ),
          deliverableUrl: "",
          deliverableUser: project?.projectAssignmentUser || [],
          createdbyUser: decoded?.name || "",
          folder: {
            folderId:
              project?.folder?.find((f) => f.folderName === "Client uploads")
                ?.folderId || "00000000-0000-0000-0000-000000000000",
            folderName: "Client uploads",
          },
          enabled: true,
          file: element.deliverableFile,
        } satisfies CreateDeliverablePayload
        payload.push(p)
      })

    const response = await createDeliverableMutation.mutateAsync(payload)

    if (!response || response.length < 1) {
      toast.error("Unsuccessful! Your deliverable has not been added", {
        autoClose: 3000,
        icon: ErrorIcon,
        toastId: "api-error-toast",
      })
      setUploadInProgress(false)
    } else {
      if (decoded?.companyId !== "001") {
        const projectManagerId =
          projectManagerQuery.data?.projectManagerId || ""

        const payload = {
          notificationType: "ClientUpload",
          clientId: client?.results?.[0]?.id,
          clientName: client?.results?.[0]?.clientName,
          reference: project?.projectAssignmentDisplayName || "",
          subject: "You have a client upload",
          message: {
            full: `A client has uploaded files to the project ${
              project?.projectCode || project?.salesOrder?.salesOrderNumber
            }.`,
          },
          user: {
            userEmail: decoded?.email || "",
          },
          htmlContent: data.files
            ?.filter((f) => f)
            ?.map((file: any) => file.deliverableFile.name)
            .join(", "),
          projectId: id!,
          projectName:
            project?.projectAssignmentDisplayName ||
            project?.projectAssignmentName ||
            "",
          projectManagerId:
            project?.projectCode || project?.opportunity?.salesLeadNumber
              ? projectManagerId
              : undefined,
          toIds: project?.projectAssignmentOwners?.map((u) => u.userId) || [],
        } satisfies ClientUploadNotification

        await sendClientUploadNotificationMutation.mutate(payload)

        const fabricPayload = {
          type: "notify_client_upload",
          targetId: project?.id || "",
          targetName: project?.projectAssignmentDisplayName || "",
        } satisfies FabricEvent

        await sendFabricEventMutation.mutate(fabricPayload)

        appInsights.trackEvent({
          name: "NOTIFY_CLIENT_UPLOAD",
          properties: {
            projectId: project?.id,
            project: project?.projectAssignmentDisplayName,
            client: client?.results?.[0]?.clientName,
            user: decoded?.email,
            projectManagerId: projectManagerId,
            toIds: project?.projectAssignmentOwners?.map((u) => u.userId),
          },
        })
      }

      toast.success("Upload successful", {
        style: {
          borderLeft: "8px solid #4CAF50",
        },
      })
      setUploadInProgress(false)
      forceCloseDrawer()
      queryClient.invalidateQueries({
        queryKey: ["getYourUploadsDeliverables"],
        exact: true,
      })
      projectQuery?.refetch()
    }
  }

  const createDeliverableMutation = useMutation<
    DeliverableModel[],
    Error,
    CreateDeliverablePayload[]
  >({
    mutationFn: (payload) => createDeliverable(payload),
    mutationKey: ["createDeliverable", client?.results?.[0].id, id],
  })

  useEffect(() => {
    clearErrors()
  }, [])
  const disabledUploadButton = () => {
    if (
      errors?.files ||
      decoded?.companyId === "001" ||
      uploadInProgress ||
      files.length < 1
    ) {
      return true
    } else {
      return false
    }
  }

  return (
    <Stack
      component="form"
      onSubmit={handleSubmit(handleFilesSubmit)}
      justifyContent={"space-evenly"}
      sx={{height: "100%"}}
    >
      <Stack spacing={2}>
        <Stack
          sx={{
            paddingLeft: "8px",
            paddingRight: "8px",
          }}
        >
          <div
            {...getRootProps()}
            className="dropzone"
            style={{
              border: "2px dashed #B5B5B5",
              borderRadius: "0.25rem",
            }}
          >
            <input {...getInputProps()} />
            <Stack
              sx={{height: "40px"}}
              alignContent={"center"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Stack
                direction="row"
                spacing={0.5}
                justifyContent={"center"}
                alignContent={"center"}
              >
                <Typography sx={{color: "#7A7A79"}}>
                  {t("Drop files here or")}
                </Typography>
                <Typography
                  sx={{
                    color: "#226CA5",
                    cursor: "pointer",
                    textDecoration: "underline",
                  }}
                >
                  {t("browse")}
                </Typography>
              </Stack>
            </Stack>
          </div>
        </Stack>

        <Stack
          spacing={2}
          flexGrow={1}
          height="calc(100vh - 504px)"
          sx={{
            overflow: "scroll",
            paddingLeft: "8px",
            paddingRight: "8px",
          }}
        >
          {files.map((file, index) =>
            file ? (
              <DeliverableInfo
                key={index}
                file={file}
                setFiles={setFiles}
                index={index}
                control={control}
                errors={errors}
                resetField={resetField}
                setError={setError}
                getValues={getValues}
                clearErrors={clearErrors}
                setValue={setValue}
                unregister={unregister}
                trigger={trigger}
              />
            ) : null,
          )}
        </Stack>
      </Stack>

      <Stack
        sx={{
          width: "100%",
          border: "1px solid #DEDEDE",
        }}
      ></Stack>

      <Stack sx={{padding: "8px"}} spacing={"1rem"}>
        <Stack direction="row" spacing={1}>
          <Stack>
            <WarningIcon fill="#F98500" />
          </Stack>
          <Stack>
            <Typography
              fontWeight={400}
              fontSize={14}
              color={"#595958"}
              id="dropzone-message"
            >
              {t(
                `Uploads will be shared with the Control Risks team working on this project. All users associated with this project will have access to your uploaded files. ${
                  decoded?.companyId === "001"
                    ? "This functionality is not available for internal Control Risks users"
                    : ""
                }`,
              )}
            </Typography>
          </Stack>
        </Stack>
        <Stack spacing={1} direction="row" justifyContent={"flex-end"}>
          <Button
            sx={{
              textTransform: "none",
              minWidth: "120px",
              height: "40px",
              backgroundColor: "white",
            }}
            onClick={() => (uploadInProgress ? null : setOpenCancelModal(true))}
          >
            <Typography
              sx={{borderBottom: "1px solid #053747", color: "#053747"}}
            >
              {t("Cancel")}
            </Typography>
          </Button>
          <Button
            type={disabledUploadButton() ? "button" : "submit"}
            disabled={disabledUploadButton() ? true : false}
            sx={
              disabledUploadButton()
                ? {
                    minWidth: "145px",
                    height: "40px",
                    textTransform: "none",
                    backgroundColor: "#EEEEEE",
                  }
                : {
                    minWidth: "145px",
                    height: "40px",
                    textTransform: "none",
                    backgroundColor: "#053747",
                  }
            }
          >
            {uploadInProgress ? (
              <Stack
                sx={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress
                  sx={{color: "#FFFFFF", width: "100%", height: "100%"}}
                />
              </Stack>
            ) : (
              <Typography
                sx={
                  disabledUploadButton() ? {color: "#9B9B9B"} : {color: "white"}
                }
              >
                {t("Upload")}&nbsp;
                {files?.filter((f) => f).length > 0
                  ? files?.filter((f) => f).length
                  : 0}
              </Typography>
            )}
          </Button>
        </Stack>
      </Stack>
    </Stack>
  )
}

export default Dropzone
