export const iconMap = {
  docx: "/file-icons/Word",
  doc: "/file-icons/Word",
  dot: "/file-icons/Word",
  dotx: "/file-icons/Word",
  dotm: "/file-icons/Word",
  docm: "/file-icons/Word",
  rtf: "/file-icons/Word",
  rtx: "/file-icons/Word",
  xlsx: "/file-icons/Excel",
  xls: "/file-icons/Excel",
  xlsm: "/file-icons/Excel",
  xlsb: "/file-icons/Excel",
  xlt: "/file-icons/Excel",
  xltx: "/file-icons/Excel",
  xltm: "/file-icons/Excel",
  csv: "/file-icons/Default",
  pptx: "/file-icons/PowerPoint",
  ppt: "/file-icons/PowerPoint",
  pptm: "/file-icons/PowerPoint",
  pps: "/file-icons/PowerPoint",
  ppsx: "/file-icons/PowerPoint",
  ppsm: "/file-icons/PowerPoint",
  potm: "/file-icons/PowerPoint",
  potx: "/file-icons/PowerPoint",
  pdf: "/file-icons/_PDF",
  fdf: "/file-icons/_PDF",
  xfdf: "/file-icons/_PDF",
  jpg: "/file-icons/Image",
  jpeg: "/file-icons/Image",
  jfif: "/file-icons/Image",
  png: "/file-icons/Image",
  tif: "/file-icons/Image",
  tiff: "/file-icons/Image",
  gif: "/file-icons/Image",
  bmp: "/file-icons/Image",
  md: "/file-icons/Default",
  txt: "/file-icons/Default",
  zip: "/file-icons/Zip",
  dwg: "/file-icons/CAD",
  dxf: "/file-icons/CAD",
  dwf: "/file-icons/CAD",
  dgn: "/file-icons/CAD",
  msg: "/file-icons/Mail",
  eml: "/file-icons/Mail",
  ost: "/file-icons/Mail",
  pst: "/file-icons/Mail",
  vsdx: "/file-icons/Visio",
  dashboards: "/file-icons/Dashboard",
  pub: "/file-icons/Default",
  rvt: "/file-icons/Default",
  mp4: "/file-icons/Video",
  webm: "/file-icons/Video",
  ogg: "/file-icons/Video",
  ogv: "/file-icons/Video",
  default: "/file-icons/Default",
}

export const getDeliverableExtension = (url: string): keyof typeof iconMap => {
  const extension = url.split(".").pop()?.toLowerCase() || ""
  return extension !== "" && iconMap[extension as keyof typeof iconMap]
    ? (extension as keyof typeof iconMap)
    : "default"
}
