import {useConfigProvider} from "../../../config/ConfigProvider"
import {CreateDeliverablePayload} from "../../../types"

export function deliverableEndpoints() {
  const {token, faToken, apimKey, serverUrl} = useConfigProvider()
  const headers = {
    "Content-Type": "application/json",
    "X-Functions-Key": faToken || "",
    "Ocp-Apim-Subscription-Key": apimKey || "",
    Authorization: `Bearer ${token}`,
  }

  const fileHeaders = {
    "Content-Type": "application/octet-stream",
    "X-Functions-Key": faToken || "",
    "Ocp-Apim-Subscription-Key": apimKey || "",
    Authorization: `Bearer ${token}`,
  }

  const headersFiles = {
    "X-Functions-Key": faToken,
    "Ocp-Apim-Subscription-Key": apimKey,
    Authorization: `Bearer ${token}`,
  }

  const getDeliverablesByProjectId = async (
    projectId: string,
    query: string,
    showExpired: boolean,
  ) => {
    const request = `${serverUrl}/projects/${projectId}/deliverables`
    return await fetch(`${request}?${query}&showExpired=${showExpired}`, {
      headers: headers,
    }).then((res) => res.json())
  }

  const getDeliverablesByFolderId = async (folderId: string, query: string) => {
    const request = `${serverUrl}/folders/${folderId}/deliverables/accessCheck`
    return await fetch(`${request}?${query}`, {headers: headers}).then((res) =>
      res.json(),
    )
  }

  const getDeliverable = async (id: string) => {
    const url = `${serverUrl}/user/deliverables/${id}`
    return await fetch(url, {headers: headers}).then((res) => res.json())
  }

  const getTableauPreview = async (region = "uk") => {
    const url = `${serverUrl}/deliverable/tableau?region=${region}`
    return await fetch(url, {
      headers: headers,
    }).then((res) => res.json())
  }

  const getPowerBIPreview = async (workspaceId = "", reportId = "") => {
    const url = `${serverUrl}/deliverable/powerbi/${workspaceId}/${reportId}`
    return await fetch(url, {
      headers: headers,
    }).then((res) => res.json())
  }

  const downloadDeliverableFile = async (
    id: string,
    fileName: string,
    contentType: string | null,
    ext: string,
  ) => {
    const url = `${serverUrl}/deliverables/${id}/download`

    const response = await fetch(url, {headers: fileHeaders})

    if (response.status != 200 && response.status != 201) {
      throw new Error(`Failed to download file: ${response.statusText}`)
    }

    const extension = contentType
      ? contentType.split("/").pop()?.toLowerCase()
      : null

    if (extension) {
      const file = new File([await response.blob()], `file.${ext}`, {
        type: contentType || undefined,
      })
      return file
    } else {
      throw new Error("File extension not found")
    }
  }

  const createDeliverable = async (payload: CreateDeliverablePayload[]) => {
    const url = `${serverUrl}/user/deliverables`

    const formData = new FormData()
    //if payload contains file
    payload.forEach((p) => {
      if (p.file) {
        formData.append("Files", new Blob([p.file]), p.deliverableName)
      }
    })
    formData.append("deliverable", JSON.stringify(payload))

    return await fetch(url, {
      method: "POST",
      headers: headersFiles as Record<string, string>,
      body: formData,
    }).then((res) => res.json())
  }

  const getDeliverableFileStatus = async (id: string) => {
    const url = `${serverUrl}/user/folders/${id}/deliverables/filestatus`
    return await fetch(url, {
      headers: headers,
    }).then((res) => res.json())
  }

  const getDeliverableFileStatusByFolderId = async (folderId: string) => {
    const url = `${serverUrl}/user/folders/${folderId}/deliverables/filestatus`
    return await fetch(url, {
      headers: headers,
    }).then((res) => res.json())
  }

  const deleteDeliverable = async (id: string) => {
    const url = `${serverUrl}/user/deliverables/${id}`
    return await fetch(url, {
      method: "DELETE",
      headers: headers,
    }).then((res) => res.json())
  }

  return {
    getDeliverablesByProjectId,
    getDeliverablesByFolderId,
    getDeliverable,
    getTableauPreview,
    getPowerBIPreview,
    downloadDeliverableFile,
    createDeliverable,
    getDeliverableFileStatusByFolderId,
    getDeliverableFileStatus,
    deleteDeliverable,
  }
}
