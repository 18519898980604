import type {FC} from "react"
import {useNavigate} from "react-router-dom"
import {Box, Divider, Typography, useTheme, Stack} from "@mui/material"
import {ReactSVG} from "react-svg"
import moment from "moment"
import {Tooltip} from "../../atoms"
import type {ProjectListCardTypes} from "./types"
import {dateFormat, truncateText} from "../../../helpers"
import {useConfigProvider} from "../../../config"
import {LockIcon} from "../../../assets/icons"

export const ProjectListCard: FC<ProjectListCardTypes> = (props) => {
  const {
    projectId = "",
    projectName = "No project",
    projectDescription = "No description",
    createdAt = "00/00/0000",
    foldersCount = 0,
    filesCount = 0,
    dashboardsCount = 0,
    strictlyConfidential = false,
  } = props
  const theme = useTheme()
  const navigate = useNavigate()
  const {basename} = useConfigProvider()
  return (
    <Box
      component="article"
      role="article"
      aria-label={projectName}
      aria-describedby={projectDescription}
      onClick={() => navigate(`${basename}/${projectId}`)}
      sx={{
        display: "flex",
        flexDirection: "row",
        textAlign: "left",
        borderWidth: "1px",
        borderStyle: "solid",
        borderColor: "transparent",
        borderRadius: "0.25rem 0.25rem 0 0",
        height: "123px",
        cursor: "pointer",
        backgroundColor: theme.palette.common.white,
        transition: "border-color 0.2s ease-in-out",
        "&:hover": {
          backgroundColor: theme.palette.common.white,
          cursor: "pointer",
          borderColor: "#375F6C",
          "> .MuiDivider-root": {
            backgroundColor: "#375F6C",
          },
        },
      }}
    >
      <Divider
        orientation="vertical"
        sx={{
          borderTopLeftRadius: "0.25rem",
          backgroundColor: "#CACDCD",
          width: "0.5rem",
          border: "none",
          transition: "background-color 0.2s ease-in-out",
        }}
      />
      <Stack
        paddingY="1rem"
        paddingRight="1.25rem"
        paddingLeft="0.75rem"
        flexGrow={1}
        gap="0.5rem"
        flexDirection="row"
        justifyContent="space-between"
      >
        <Stack
          flexDirection="column"
          justifyContent="flex-start"
          gap="0.5rem"
          maxWidth="906px"
        >
          <Stack flexDirection="row" gap="16px" alignItems="center">
            <Typography
              component="h3"
              fontSize="1rem"
              fontWeight="600"
              lineHeight="1.5rem"
              color="#242D35"
              sx={{
                display: ["-webkit-box", "box"],
                margin: 0,
                overflow: "hidden",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: 1,
              }}
            >
              {truncateText(projectName, 60)}
            </Typography>
            {strictlyConfidential ? (
              <Tooltip title={"Strictly confidential"} arrow placement="right">
                <div
                  style={{
                    width: "20px",
                    height: "20px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <LockIcon height="30" width="30" style={{flexShrink: 0}} />
                </div>
              </Tooltip>
            ) : null}
          </Stack>
          {projectDescription.split(/\r\n|\r|\n/).length > 3 ? (
            <Box>
              <Tooltip
                arrow
                title={projectDescription}
                placement="top"
                sx={{
                  whiteSpace: "pre-line",
                }}
              >
                <Typography
                  component="p"
                  color="#242D35"
                  fontSize="0.875rem"
                  fontWeight="400"
                  lineHeight="1.25rem"
                  sx={{
                    display: ["-webkit-box", "box"],
                    overflow: "hidden",
                    WebkitBoxOrient: "vertical",
                    WebkitLineClamp: 3,
                    wordBreak: "break-word",
                    whiteSpace: "pre-line",
                  }}
                >
                  {truncateText(projectDescription, 330)}
                </Typography>
              </Tooltip>
            </Box>
          ) : (
            <Typography
              component="p"
              color="#242D35"
              fontSize="0.875rem"
              fontWeight="400"
              lineHeight="1.25rem"
              sx={{
                display: ["-webkit-box", "box"],
                overflow: "hidden",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: 3,
                wordBreak: "break-word",
                whiteSpace: "pre-line",
              }}
            >
              {truncateText(projectDescription, 330)}
            </Typography>
          )}
        </Stack>
        <Stack
          flexDirection="row"
          gap="1.25rem"
          alignItems="flex-start"
          marginLeft="auto"
        >
          <Stack flexDirection="row" gap="0.5rem" whiteSpace="nowrap">
            <Tooltip arrow title="Folders" placement="right">
              <Stack flexDirection="row" alignItems="center" gap={0.5}>
                <ReactSVG
                  src="/icons/folder.svg"
                  wrapper="span"
                  className="icon-wrapper"
                />
                <Typography color="#4f5b67">{foldersCount}</Typography>
              </Stack>
            </Tooltip>
            <Tooltip arrow title="Dashboards" placement="right">
              <Stack flexDirection="row" alignItems="center" gap={0.5}>
                <ReactSVG
                  src="/icons/dashboard.svg"
                  wrapper="span"
                  className="icon-wrapper"
                />
                <Typography color="#4f5b67">{dashboardsCount}</Typography>
              </Stack>
            </Tooltip>
            <Tooltip arrow title="Files" placement="right">
              <Stack flexDirection="row" alignItems="center" gap={0.5}>
                <ReactSVG
                  src="/icons/clip.svg"
                  wrapper="span"
                  className="icon-wrapper"
                />
                <Typography color="#4f5b67">{filesCount}</Typography>
              </Stack>
            </Tooltip>
          </Stack>
          <Stack flexDirection="row" gap="0.5rem" whiteSpace="nowrap">
            <Typography
              fontSize="0.875rem"
              fontWeight="400"
              lineHeight="1.25rem"
              color="#595958"
            >
              Created
            </Typography>
            <Typography
              fontSize="0.875rem"
              fontWeight="400"
              lineHeight="1.25rem"
              color="#595958"
            >
              {moment(createdAt).format(dateFormat) || ""}
            </Typography>
          </Stack>
        </Stack>
        <Stack></Stack>
      </Stack>
    </Box>
  )
}
