import {useConfigProvider} from "../../../config"

export const accountEndpoints = () => {
  const {token, userProfileServerUrl, userProfileApimKey, userProfileFaToken} =
    useConfigProvider()

  const userHeaders = {
    "Content-Type": "application/json",
    "X-Functions-Key": userProfileFaToken || "",
    "Ocp-Apim-Subscription-Key": userProfileApimKey || "",
    Authorization: `Bearer ${token}`,
  }

  const acceptTermsAndConditions = async (emailAddress: string) => {
    const request = new Request(
      `${userProfileServerUrl}/acceptTermsAndConditions/${emailAddress}`,
      {
        headers: userHeaders,
        method: "GET",
      },
    )

    const response = await fetch(request)

    if (!response.ok) {
      throw new Error("Failed to accept terms and conditions")
    }

    return response.json() as Promise<boolean>
  }

  return {
    acceptTermsAndConditions,
  }
}
